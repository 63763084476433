import { Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import styles from './SubstitutionRoomsSelect.module.scss';
import { SortDirection } from '@bp/planung-graphql-types';
import { use_SchoolEventsQuery } from '../../../types/planung-graphql-client-defs';
import { useSubstitutionStore } from '../SubstitutionProvider';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { isFirstBetweenSecondAndThird } from '../../../utils/dateCalculations';
import { useMemo, useState } from 'react';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';

type SubstitutionRoomsSelectProps = {
  eventRoomUuids: string[];
  eventStart: Date;
  eventEnd: Date;
  error: string | null;
  onChange: (uuids: string[]) => void;
};

export const SubstitutionRoomsSelect = ({
  eventRoomUuids,
  eventStart,
  eventEnd,
  error,
  onChange,
}: SubstitutionRoomsSelectProps) => {
  const { t } = useTranslation();
  const eventContext = useMemorizedCacheTag('EVENT');

  const { selectedDay } = useSubstitutionStore();

  const selectedDayStart = selectedDay.utc().startOf('day').toISOString();
  const selectedDayEnd = selectedDay.utc().endOf('day').toISOString();

  const [roomUuids, setRoomUuids] = useState<string[]>(eventRoomUuids);

  const { roomsData } = useLoadBasicData({ pause: false });

  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ isSchoolEvent: SortDirection.Desc }, { start: SortDirection.Asc }],
      },
      where: {
        OR: [
          {
            start_GTE: selectedDayStart,
            end_LTE: selectedDayEnd,
          },
          {
            start_LTE: selectedDayStart,
            end_GTE: selectedDayEnd,
          },
          {
            AND: [
              {
                start_GTE: selectedDayStart,
                start_LTE: selectedDayEnd,
              },
            ],
          },
        ],
      },
    },
    context: eventContext,
  });

  const availableRooms: SubstitutionDataEntry[] = useMemo(() => {
    const rooms: SubstitutionDataEntry[] = [];

    // find rooms that are taken at the same time
    const sameTimeRoomUuids =
      eventData?.events
        .filter((event) => isFirstBetweenSecondAndThird(event.start, eventStart, eventEnd, 'minutes'))
        .flatMap((event) => {
          return event.rooms.map((room) => room.uuid);
        }) ?? [];

    // get all rooms
    const allRoomUuids = roomsData.rooms.filter((room) => room.classroom).map((room) => room.uuid);
    // substract taken rooms
    const availableRoomUuids = allRoomUuids.filter((uuid) => !sameTimeRoomUuids.includes(uuid));
    // push rooms from current event
    eventRoomUuids.forEach((uuid) => availableRoomUuids.push(uuid));

    // map available rooms
    availableRoomUuids.forEach((uuid) => {
      const room = roomsData.rooms.find((room) => room.uuid === uuid);
      if (room) {
        rooms.push({
          uuid: room.uuid,
          name: room.name,
          shortName: room.roomNumber ?? '',
        });
      }
    });

    return rooms.sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }, [eventData?.events, roomsData.rooms, eventEnd, eventRoomUuids, eventStart]);

  const roomOptions = useCreateSelectOptions(availableRooms, 'uuid', 'name');

  return (
    <Select
      label={t('substitutions.availableRooms')}
      options={roomOptions}
      onChange={(option) => {
        const values = option as MultiValue<SelectOptionType>;
        setRoomUuids(values.map((v) => v.value as string));
        onChange(values.map((v) => v.value as string));
      }}
      value={roomOptions.filter((ro) => roomUuids.some((ev) => ev === ro.value))}
      isMulti
      isClearable
      isSearchable
      name='available-rooms'
      menuPosition='fixed'
      error={error ?? undefined}
      className={styles['substitution-rooms-select']}
    />
  );
};
