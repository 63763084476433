import { Grid, GridRow, Input, ModalBottomButtons } from '@bp/ui-components';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import styles from './WorkOrderForm.module.scss';
import { SubstitutionRoomsSelect } from '../components/SubstitutionRoomsSelect';

type WorkOrderFormProps = {
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  onClose: () => void;
};

type WorkOrderFormType = {
  roomUuids: string[];
  comment: string;
};

export const WorkOrderForm = ({ originTeachers, originRooms, eventStart, eventEnd, onClose }: WorkOrderFormProps) => {
  const { t } = useTranslation();

  const initialValues: WorkOrderFormType = {
    roomUuids: [],
    comment: '',
  };

  async function handleSubmit(values: WorkOrderFormType, formHelpers: FormikHelpers<WorkOrderFormType>) {
    // TODO: implement
  }

  return (
    <Formik<WorkOrderFormType> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form className={styles['work-order-form']}>
            <Grid useFormGap>
              <GridRow className={styles.info} spacingBottom='s'>
                {t('substitutions.initialTeacher')}:
                <span className={styles.bold}>
                  {originTeachers.length > 0 ? originTeachers.map((t) => t.name).join(', ') : t('common.none')}
                </span>
              </GridRow>
              <GridRow className={styles.info} spacingTop='none'>
                {t('substitutions.initialRooms')}:
                <span className={styles.bold}>
                  {originRooms.length > 0 ? originRooms.map((r) => r.name).join(', ') : t('common.none')}
                </span>
              </GridRow>
              <GridRow spacingBottom='s'>
                <SubstitutionRoomsSelect
                  eventRoomUuids={originRooms.map((r) => r.uuid)}
                  eventStart={eventStart}
                  eventEnd={eventEnd}
                  error={errors.roomUuids as string}
                  onChange={async (uuids) => await setFieldValue('roomUuids', uuids)}
                />
              </GridRow>
              <GridRow spacingTop='s'>
                <Input
                  name='comment'
                  label={t('common.comment')}
                  onChange={async (e) => setFieldValue('comment', e.target.value)}
                  value={values.comment}
                  error={errors.comment}
                />
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              submitButton={{
                disabled: isSubmitting || !dirty,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
