import { useState } from 'react';
import { SchoolEventTable } from '../../../../components/SchoolEvent/SchoolEventTable';
import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { SchoolEventModal } from '../../../../components/SchoolEvent/SchoolEventModal';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { use_DeleteSchoolEventsMutation } from '../../../../types/planung-graphql-client-defs';
import { useConfirm } from '../../../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../../utils/toast';
import { observer } from 'mobx-react-lite';

export type EventRepetitions = 'daily' | 'weekly' | 'weekdays' | 'annually';

export type SchoolEventType = {
  uuid: string;
  name: string;
  classUuids: string[];
  groupUuids: string[];
  subjectUuid: string;
  teacherUuids: string[];
  startDateTime: Date;
  endDateTime: Date;
  startTime: string; // only used for error messages
  endTime: string; // only used for error messages
  repetition: EventRepetitions | null;
  lastRepetition: Date;
  isClassbookRelevant: boolean;
};

export const SetupSchoolEvent = observer(() => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('EVENT');

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  const [, deleteEvent] = use_DeleteSchoolEventsMutation();

  const { confirm, ConfirmationDialog } = useConfirm();

  function handleAdd() {
    setSelectedUuid(null);
    setModalOpen(true);
  }

  function handleEdit(uuid: string) {
    setSelectedUuid(uuid);
    setModalOpen(true);
  }

  function handleDuplicate(uuid: string) {
    console.log('TODO: implement');
  }

  async function handleDelete(uuid: string) {
    const res = await confirm({ title: t('schoolEvents.delete.title'), message: t('schoolEvents.delete.warning') });
    if (res) {
      const res = await deleteEvent({ uuids: { uuids: [uuid] } }, context);
      if (res.error) {
        showUserErrorToast({ error: res.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  }

  return (
    <Page title={t('schoolEvents.title', { count: 2 })}>
      <SchoolEventTable onAdd={handleAdd} onEdit={handleEdit} onDuplicate={handleDuplicate} onDelete={handleDelete} />

      <SchoolEventModal selectedEventUuid={selectedUuid} isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <ConfirmationDialog />
    </Page>
  );
});
