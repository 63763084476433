import { useTranslation } from 'react-i18next';
import {
  SubstitutionEventsTableType,
  SubstitutionEventsTable,
} from '../../../../components/Substitutions/tables/SubstitutionEventsTable';
import {
  ActionBar,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  InvisibleIcon,
  TimetableView,
  TimetableViewEntryType,
  UploadIcon,
  VisibleIcon,
} from '@bp/ui-components';
import styles from './PlanSubstitutions.module.scss';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SortDirection,
  use_SchoolEventsQuery,
  use_UpdateSingleEventMutation,
  useAbsencesQuery,
  useTimeGridEntriesByTimeGridQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import classNames from 'classnames';
import { SubstitutionModal } from '../../../../components/Substitutions/modals/SubstitutionModal';
import {
  isFirstSameOrAfterSecond,
  isFirstSameOrBeforeSecond,
  normalizedDate,
} from '../../../../utils/dateCalculations';
import { useSubstitutionStore } from '../../../../components/Substitutions/SubstitutionProvider';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { CancelEventModal } from '../../../../components/Substitutions/modals/CancelEventModal';
import { WorkOrderModal } from '../../../../components/Substitutions/modals/WorkOrderModal';
import { DifferentTimeModal } from '../../../../components/Substitutions/modals/DifferentTimeModal';
import { MergeEventModal } from '../../../../components/Substitutions/modals/MergeEventModal';
import { EventState } from '@bp/planung-graphql-types';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { SubstitutionFormRowStatus } from '../../../../components/Substitutions/forms/SubstitutionFormRow';

export type SubstitutionDataEntry = {
  uuid: string;
  name: string;
  shortName: string;
};

export type SubstitutionDataGroupEntry = SubstitutionDataEntry & {
  length: number;
  index: number;
};

export type SubstitutionDataTeacherEntry = SubstitutionDataEntry & {
  status: Extract<SubstitutionFormRowStatus, 'normal'>;
};

export type SubstitutionDataMissingTeacherEntry = SubstitutionDataEntry & {
  status: Extract<SubstitutionFormRowStatus, 'cancelation-class-trip' | 'on-class-trip' | 'signed-out'>;
  fullDay: boolean;
};

export type SubstitutionDataSubstitutionTeacherEntry = SubstitutionDataEntry & {
  status: Extract<SubstitutionFormRowStatus, 'substitution'>;
  givenSubstituteHours: number | null;
};

export const PlanSubstitutions = observer(() => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const timeGridContext = useMemorizedCacheTag('TIME_GRID');
  const eventContext = useMemorizedCacheTag('EVENT');
  const absenceContext = useMemorizedCacheTag('ABSENCES');

  const [showAll, setShowAll] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedClassEntries, setSelectedClassEntries] = useState<SubstitutionDataEntry[]>([]);
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>(0);
  const [selectedEvent, setSelectedEvent] = useState<SubstitutionEventsTableType | null>(null);
  const [selectedGroupUuid, setSelectedGroupUuid] = useState<string | null>(null);

  const [substitutionModalOpen, setSubstitutionModalOpen] = useState<boolean>(false);
  const [cancelEventModalOpen, setCancelEventModalOpen] = useState<boolean>(false);
  const [mergeEventModalOpen, setMergeEventModalOpen] = useState<boolean>(false);
  const [workOrderModalOpen, setWorkOrderModalOpen] = useState<boolean>(false);
  const [differentTimeModalOpen, setDifferentTimeModalOpen] = useState<boolean>(false);

  const { selectedDay } = useSubstitutionStore();

  const [{ data: timeGridEntriesData }] = useTimeGridEntriesByTimeGridQuery({
    context: timeGridContext,
  });

  const timetableStart =
    timeGridEntriesData && timeGridEntriesData.timeGridEntries[0]
      ? dayjs(timeGridEntriesData?.timeGridEntries[0].start).startOf('hour')
      : dayjs().set('hour', 6);

  const timetableEnd =
    timeGridEntriesData && timeGridEntriesData.timeGridEntries[timeGridEntriesData.timeGridEntries.length - 1]
      ? dayjs(timeGridEntriesData?.timeGridEntries[timeGridEntriesData.timeGridEntries.length - 1].end).endOf('hour')
      : dayjs().set('hour', 18);

  // normalize date so that it is compareable
  const normalizedTimetableStart = normalizedDate(timetableStart, selectedDay);
  const normalizedTimetableEnd = normalizedDate(timetableEnd, selectedDay);

  const selectedDayStart = selectedDay.utc().startOf('day').toISOString();
  const selectedDayEnd = selectedDay.utc().endOf('day').toISOString();

  const [, updateSingleEvent] = use_UpdateSingleEventMutation();

  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ isSchoolEvent: SortDirection.Desc }, { start: SortDirection.Asc }],
      },
      where: {
        OR: [
          {
            start_GTE: selectedDayStart,
            end_LTE: selectedDayEnd,
          },
          {
            start_LTE: selectedDayStart,
            end_GTE: selectedDayEnd,
          },
          {
            AND: [
              {
                start_GTE: selectedDayStart,
                start_LTE: selectedDayEnd,
              },
            ],
          },
        ],
      },
    },
    context: eventContext,
  });

  const { classesData, subjectData, teacherData, groupsData, divisionsData, roomsData } = useLoadBasicData({
    pause: !eventData,
  });

  const [{ data: absencesData }] = useAbsencesQuery({
    variables: {
      where: {
        OR: [
          {
            start_GTE: selectedDayStart,
            end_LTE: selectedDayEnd,
          },
          {
            start_LTE: selectedDayStart,
            end_GTE: selectedDayEnd,
          },
          {
            AND: [
              {
                start_GTE: selectedDayStart,
                start_LTE: selectedDayEnd,
              },
            ],
          },
        ],
      },
    },
    context: absenceContext,
    pause: !selectedDay,
  });

  const [{ data: schoolEventsData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ start: SortDirection.Desc }],
      },
      where: {
        isSchoolEvent: true,
        OR: [
          { start_GTE: currentSchoolYear?.start, end_LTE: currentSchoolYear?.end },
          {
            start_LTE: currentSchoolYear?.start,
            end_GTE: currentSchoolYear?.end,
          },
        ],
      },
    },
    context: eventContext,
  });

  const events: SubstitutionEventsTableType[] = useMemo(() => {
    // map events
    const events: SubstitutionEventsTableType[] =
      eventData?.events.map((event) => {
        const subject = subjectData?.subjects.find((s) => s.uuid === event.subject.uuid);
        const eventStart = dayjs(event.start).utc().toDate();
        const eventEnd = dayjs(event.end).utc().toDate();

        // map teachers
        const teachers: SubstitutionDataTeacherEntry[] = event.personsConnection.edges.map((edge) => {
          const teacher = teacherData?.people.find((t) => t.uuid === edge.node.uuid);
          return {
            uuid: teacher?.uuid ?? '',
            name: teacher?.displayName ?? '',
            shortName: teacher?.shortName ?? '',
            status: 'normal',
          };
        });

        // find and map substitution teachers
        const substitutionTeachers: SubstitutionDataSubstitutionTeacherEntry[] = event.personsConnection.edges
          .filter((edge) => {
            return edge.properties.isCoveringTeacher;
          })
          .map((edge) => {
            const teacher = teacherData?.people.find((t) => t.uuid === edge.node.uuid);
            return {
              uuid: teacher?.uuid ?? '',
              name: teacher?.displayName ?? '',
              shortName: teacher?.shortName ?? '',
              status: 'substitution',
              givenSubstituteHours: edge.properties.givenSubstituteHours ?? null,
            };
          });

        // find and map missing teachers
        const missingTeachers: SubstitutionDataMissingTeacherEntry[] = event.originalPersons
          .filter((op) => !event.personsConnection.edges.map((e) => e.node.uuid).includes(op.uuid))
          .map((_teacher) => {
            const teacher = teacherData?.people.find((t) => t.uuid === _teacher.uuid); // missing displayname
            const absence = absencesData?.absences.find((a) => a.person.uuid === teacher?.uuid);
            const absenceStart = dayjs(absence?.start).utc();
            const absenceEnd = dayjs(absence?.end).utc();

            const schoolEvent = schoolEventsData?.events.find((e) =>
              e.personsConnection.edges.some((edge) => edge.node.uuid === teacher?.uuid),
            );
            const schoolEventStart = dayjs(schoolEvent?.start).utc();
            const schoolEventEnd = dayjs(schoolEvent?.end).utc();

            const fullDay =
              (!!absence &&
                isFirstSameOrBeforeSecond(absenceStart, eventStart) &&
                isFirstSameOrAfterSecond(absenceEnd, eventEnd)) ||
              (!!schoolEvent &&
                isFirstSameOrBeforeSecond(schoolEventStart, eventStart) &&
                isFirstSameOrAfterSecond(schoolEventEnd, eventEnd));

            let status: SubstitutionFormRowStatus = 'signed-out';
            if (absence) {
              status = 'signed-out';
            }
            if (schoolEvent && fullDay) {
              status = 'on-class-trip';
            }
            if (schoolEvent && !fullDay) {
              status = 'cancelation-class-trip';
            }

            return {
              uuid: teacher?.uuid ?? '',
              name: teacher?.displayName ?? '',
              shortName: teacher?.shortName ?? '',
              status: status,
              fullDay: fullDay,
            };
          });

        return {
          uuid: event.uuid,
          output: event.print,
          start: eventStart,
          end: eventEnd,
          involvedClasses: event.involvedOriginalClasses,
          classes:
            classesData?.classes
              .filter((c) => event.classesOrGroups.map((cg) => cg.uuid).includes(c.uuid))
              .map((c) => {
                return {
                  uuid: c.uuid ?? '',
                  name: c.name ?? '',
                  shortName: c.shortName ?? '',
                };
              }) ?? [],
          groups: event.classesOrGroups
            .filter((cg) => cg.__typename === 'Group')
            .map((g) => {
              const group = groupsData?.groups.find((group) => group.uuid === g.uuid);
              const division = divisionsData?.divisions.find(
                (d) => d.uuid === group?.divisionConnection.edges[0].node.uuid,
              );
              const edge = division?.groupsConnection.edges.find((e) => e.node.uuid === group?.uuid);

              return {
                uuid: group?.uuid ?? '',
                name: `${group?.name} (${division?.class?.name})`,
                shortName: `${group?.shortName} (${division?.class?.shortName})`,
                length: division?.groupsConnection.totalCount ?? 1,
                index: edge?.properties.order ?? 0,
              };
            })
            .sort(),
          subject: {
            uuid: subject?.uuid ?? '',
            name: subject?.name ?? '',
            shortName: subject?.shortName ?? '',
          },
          rooms: event.rooms.map((r) => {
            const room = roomsData?.rooms.find((room) => room.uuid === r.uuid);
            return {
              uuid: room?.uuid ?? '',
              name: room?.name ?? '',
              shortName: room?.roomNumber ?? '',
            };
          }),
          teachers: teachers,
          missingTeachers: missingTeachers,
          substitutionTeachers: substitutionTeachers,
          action: '',
          comment: event.comment ?? '',
          conflicts: JSON.parse(event.currentState ?? '[]'),
          isSchoolEvent: event.isSchoolEvent,
          substitutionReason: event.substitutionReasons ?? null,
        };
      }) ?? [];

    // group by start
    const groupedByStart = events.reduce(
      (acc, event) => {
        const start = event.start.toISOString();
        if (!acc[start]) {
          acc[start] = [];
        }
        acc[start].push(event);
        return acc;
      },
      {} as Record<string, SubstitutionEventsTableType[]>,
    );

    // sort by class
    const sortedEvents = Object.values(groupedByStart).map((group) =>
      group.sort((a, b) => {
        const aClass = a.classes[0]?.name ?? '';
        const bClass = b.classes[0]?.name ?? '';
        return aClass.localeCompare(bClass);
      }),
    );

    return sortedEvents.flat();
  }, [
    absencesData?.absences,
    classesData?.classes,
    divisionsData?.divisions,
    eventData?.events,
    groupsData?.groups,
    roomsData?.rooms,
    schoolEventsData?.events,
    subjectData?.subjects,
    teacherData?.people,
  ]);

  const filteredEvents: SubstitutionEventsTableType[] = useMemo(() => {
    const filtered = events.filter((e) =>
      e.substitutionReason
        ? true
        : e.conflicts.some((s) => {
            return s.state.includes(EventState.PersonNotAvailable) || s.state.includes(EventState.PersonMissing);
          }),
    );
    return showAll ? events : filtered;
  }, [events, showAll]);

  const timetableEntries: TimetableViewEntryType[] = useMemo(() => {
    if (!selectedClassEntries[selectedClassIndex]) return [];

    const entries: TimetableViewEntryType[] = [];
    const classEvents = events.filter((s) =>
      s.involvedClasses.some((c) => c.uuid === selectedClassEntries[selectedClassIndex].uuid),
    );

    for (const classEvent of classEvents) {
      const subject = subjectData?.subjects.find((s) => s.uuid === classEvent.subject.uuid);

      const entry = {
        uuid: classEvent.uuid,
        start: classEvent.start,
        end: classEvent.end,
        bgColor: subject?.timetableConfig?.color ?? undefined,
        field1: subject?.shortName,
        field4: Array.from(new Set(classEvent.teachers.map((t) => t.shortName))).join(', '),
        tooltip: {
          field1: subject?.name,
          field4: Array.from(new Set(classEvent.teachers.map((t) => t.name))).join(', '),
        },
      };

      if (classEvent.groups.length > 0) {
        classEvent.groups
          .filter((g) => {
            const selectedClass = classesData?.classes.find(
              (c) => c.uuid === selectedClassEntries[selectedClassIndex].uuid,
            );
            return selectedClass && g.shortName.includes(selectedClass.shortName);
          })
          .forEach((group) => {
            entries.push({
              ...entry,
              field2: group.shortName,
              tooltip: {
                ...entry.tooltip,
                field2: group.name,
              },
              group: {
                uuid: group.uuid,
                index: group.index ?? 0,
                length: group.length ?? 0,
              },
            });
          });
      } else {
        entries.push({
          ...entry,
          field2: Array.from(new Set(classEvent.classes.map((c) => c.shortName))).join(', '),
          tooltip: {
            ...entry.tooltip,
            field2: Array.from(new Set(classEvent.classes.map((c) => c.name))).join(', '),
          },
        });
      }
    }

    return entries;
  }, [selectedClassEntries, selectedClassIndex, classesData?.classes, subjectData?.subjects, events]);

  function handleClassSelection(direction: 'right' | 'left') {
    let newIndex = selectedClassIndex;
    if (direction === 'right') {
      newIndex = newIndex + 1 > selectedClassEntries.length - 1 ? 0 : newIndex + 1;
    } else {
      newIndex = newIndex - 1 < 0 ? selectedClassEntries.length - 1 : newIndex - 1;
    }
    setSelectedClassIndex(newIndex);
  }

  const selectEvent = useCallback(
    (uuid: string | null) => {
      if (uuid) {
        const event = events.find((s) => s.uuid === uuid);
        setSelectedEvent(event ?? null);
      } else {
        setSelectedEvent(null);
        setSelectedGroupUuid(null);
      }
    },
    [events],
  );

  const handleRowSelection = useCallback(
    (uuid: string) => {
      const classes = events.find((e) => e.uuid === uuid)?.involvedClasses;
      if (classes) {
        setSelectedClassEntries(classes);
        setSelectedClassIndex(0);
      }
    },
    [events],
  );

  async function handleOutputChange(uuid: string, output: boolean) {
    await updateSingleEvent({
      uuid,
      update: {
        print: output,
      },
    });
    const event = events.find((e) => e.uuid === uuid);
    if (event) {
      event.output = output;
    }
  }

  function handlePageChange() {
    selectEvent(null);
    setSelectedClassEntries([]);
  }

  function handleSubstitute(uuid: string) {
    selectEvent(uuid);
    setSubstitutionModalOpen(true);
  }

  function handleCancelEvent(uuid: string) {
    selectEvent(uuid);
    setCancelEventModalOpen(true);
  }

  function handleMergeEvent(uuid: string, groupUuid: string) {
    selectEvent(uuid);
    setSelectedGroupUuid(groupUuid);
    setMergeEventModalOpen(true);
  }

  function handleWorkOrder(uuid: string) {
    selectEvent(uuid);
    setWorkOrderModalOpen(true);
  }

  function handleDifferentTime(uuid: string) {
    selectEvent(uuid);
    setDifferentTimeModalOpen(true);
  }

  useEffect(() => {
    setShowAll(false);
    selectEvent(null);
    setSelectedClassEntries([]);
  }, [selectedDay, selectEvent]);

  useEffect(() => {
    selectEvent(null);
    setSelectedClassEntries([]);
  }, [showAll, selectEvent]);

  return (
    <div className={styles['plan-stubstitutions']}>
      <ActionBar
        onGlobalFilterChange={(value) => setFilter(value)}
        showGlobalFilter={false}
        extendedActionsRight={
          <>
            <Button
              hierarchy='tertiary'
              icon={showAll ? <InvisibleIcon /> : <VisibleIcon />}
              onClick={() => {
                setShowAll(!showAll);
              }}
              className={styles['all-button']}
            >
              {showAll ? t('substitutions.showRelevant') : t('common.showAll')}
            </Button>
            <Button hierarchy='tertiary' icon={<UploadIcon />} onClick={() => console.log('TODO: implement')} disabled>
              {t('substitutions.publish')}
            </Button>
          </>
        }
        showPrintButton
        disablePrintButton
        onPrintClick={() => console.log('TODO: implement')}
      />
      <Grid useFormGap>
        <GridRow>
          <GridColumn width={9}>
            <SubstitutionEventsTable
              showAll={showAll}
              filter={filter}
              data={filteredEvents}
              onSubstitute={handleSubstitute}
              onCancelEvent={handleCancelEvent}
              onMergeEvent={handleMergeEvent}
              onWorkOrder={handleWorkOrder}
              onDifferentTime={handleDifferentTime}
              onRowSelect={handleRowSelection}
              onPageChange={handlePageChange}
              onOutputChange={handleOutputChange}
            />
          </GridColumn>
          <GridColumn width={3}>
            <Card>
              <div className={styles['class-selection']}>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowLeftIcon />}
                  disabled={selectedClassEntries.length <= 1}
                  onClick={() => handleClassSelection('left')}
                />
                <div
                  className={classNames(styles['selected-class'], {
                    [styles.empty]: selectedClassEntries.length === 0,
                  })}
                >
                  {selectedClassEntries[selectedClassIndex]?.name ?? t('substitutions.noClassSelected')}
                </div>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowRightIcon />}
                  disabled={selectedClassEntries.length <= 1}
                  onClick={() => handleClassSelection('right')}
                />
              </div>
              <TimetableView
                showTimegrid
                start={timetableStart.toDate()}
                end={timetableEnd.toDate()}
                entries={timetableEntries}
                padding='s'
              />
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>

      {selectedEvent && (
        <SubstitutionModal
          isOpen={substitutionModalOpen}
          originTeachers={selectedEvent.missingTeachers}
          originRooms={selectedEvent.rooms}
          eventStart={selectedEvent.start}
          eventEnd={selectedEvent.end}
          eventUuid={selectedEvent.uuid}
          timetableStart={normalizedTimetableStart.toDate()}
          timetableEnd={normalizedTimetableEnd.toDate()}
          events={events.filter((e) => !e.isSchoolEvent)}
          onClose={() => {
            setSubstitutionModalOpen(false);
            selectEvent(null);
          }}
        />
      )}

      {selectedEvent && (
        <CancelEventModal
          isOpen={cancelEventModalOpen}
          onClose={() => {
            setCancelEventModalOpen(false);
            selectEvent(null);
          }}
        />
      )}

      {selectedEvent && selectedGroupUuid && (
        <MergeEventModal
          isOpen={mergeEventModalOpen}
          originTeachers={selectedEvent.missingTeachers}
          originRooms={selectedEvent.rooms}
          eventStart={selectedEvent.start}
          eventEnd={selectedEvent.end}
          timetableStart={timetableStart.toDate()}
          timetableEnd={timetableEnd.toDate()}
          timetableEntries={timetableEntries}
          originalEntryUuid={selectedGroupUuid}
          onClose={() => {
            setMergeEventModalOpen(false);
            selectEvent(null);
          }}
        />
      )}

      {selectedEvent && (
        <WorkOrderModal
          isOpen={workOrderModalOpen}
          originTeachers={selectedEvent.missingTeachers}
          originRooms={selectedEvent.rooms}
          eventStart={selectedEvent.start}
          eventEnd={selectedEvent.end}
          onClose={() => {
            setWorkOrderModalOpen(false);
            selectEvent(null);
          }}
        />
      )}

      {selectedEvent && (
        <DifferentTimeModal
          isOpen={differentTimeModalOpen}
          originTeachers={selectedEvent.missingTeachers}
          originRooms={selectedEvent.rooms}
          onClose={() => {
            setDifferentTimeModalOpen(false);
            selectEvent(null);
          }}
        />
      )}
    </div>
  );
});
