import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { ArrowLeftIcon, ArrowRightIcon, Button, Card, Grid, GridColumn, GridRow, showToast } from '@bp/ui-components';
import {
  use_SchoolEventsQuery,
  useCountOrganisationEventsForRangeQuery,
  useEventJobsQuery,
} from '../../types/planung-graphql-client-defs';
import { EventJobStatus, SortDirection } from '@bp/planung-graphql-types';
import { useState } from 'react';
import { useConfirm } from '../../hooks/useConfirm';
import { createEvents, deleteEvents } from '../../utils/eventsApi';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';

export const AdminEvents = observer(() => {
  const { pimAuthClaims } = useAuthClaims();

  const userConfigContext = useUserConfigContext();

  const schoolYear = userConfigContext.selectedSchoolYear;
  const [offset, setOffset] = useState(0);

  const [{ data: organizationData }] = useCountOrganisationEventsForRangeQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      start: schoolYear?.start,
      end: schoolYear?.end,
    },
    pause: !schoolYear,
    requestPolicy: 'network-only',
  });

  const status = EventJobStatus.Running;
  const [{ data: eventJobsData }, execute] = useEventJobsQuery({
    variables: {
      where: {
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        status: status,
      },
    },
    pause: !schoolYear || !status,
    requestPolicy: 'network-only',
  });

  const [{ data }] = use_SchoolEventsQuery({
    variables: {
      where: {
        start_LTE: schoolYear?.end,
        end_GTE: schoolYear?.start,
      },
      options: {
        sort: [{ start: SortDirection.Asc }],
        limit: 50,
        offset: offset,
      },
    },
    pause: !schoolYear,
    requestPolicy: 'network-only',
  });

  const { ConfirmationDialog, confirm } = useConfirm();
  const handleCreateEvents = async () => {
    await confirm({
      title: 'Create Events',
      message: 'Do you really want to create events?',
      confirmText: 'Yes',
      cancelText: 'No',
      onConfirm: async () => {
        if (schoolYear) {
          await createEvents({
            organizationUuid: pimAuthClaims.getOrganizationUuid(),
            schoolYearUuid: schoolYear?.uuid,
          });
          showToast('Events creation started. Please wait up to 20 minutes for the changes to take effect.', {
            autoClose: 1200000,
            type: 'success',
          });
        }
        const intervalId = setInterval(() => {
          execute();
        }, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
        }, 1200000);
      },
    });
  };

  const handleDeleteEvents = async () => {
    await confirm({
      title: 'Delete Events',
      message: 'Do you really want to delete all events?',
      confirmText: 'Yes',
      cancelText: 'No',
      onConfirm: async () => {
        if (schoolYear) {
          await deleteEvents({
            organizationUuid: pimAuthClaims.getOrganizationUuid(),
            schoolYearUuid: schoolYear?.uuid,
          });
          showToast('Events deletion started. Please wait up to 5 seconds for the changes to take effect.', {
            autoClose: 5000,
            type: 'success',
          });
        }
      },
    });
  };
  return (
    <Grid>
      <GridRow>
        <GridColumn width={6}>
          <Card>
            <Button
              onClick={handleCreateEvents}
              className={'mr-8'}
              hierarchy={'primary'}
              disabled={
                !data?.events || data?.events.length > 0 || !eventJobsData || eventJobsData?.eventJobs.length > 0
              }
            >
              Create Events
            </Button>
            <Button
              onClick={handleDeleteEvents}
              hierarchy={'primary'}
              disabled={!eventJobsData || eventJobsData?.eventJobs.length > 0}
            >
              Delete all Events
            </Button>
          </Card>
        </GridColumn>
        <GridColumn width={6}>
          <Card
            header={{
              headline: 'Events',
              bigActionsGap: true,
              actions: [
                {
                  icon: <ArrowLeftIcon />,
                  hierarchy: 'secondary',
                  iconPosition: 'left',
                  disabled: offset === 0,
                  text: 'Pre',
                  callback: () => {
                    setOffset(offset - 50);
                  },
                },
                {
                  text: `${offset + 1} - ${offset + 50}`,
                },
                {
                  text: `Total: ${organizationData?.eventsConnection.totalCount}`,
                },
                {
                  icon: <ArrowRightIcon />,
                  iconPosition: 'right',
                  hierarchy: 'secondary',
                  text: 'Next',
                  callback: () => {
                    setOffset(offset + 50);
                  },
                },
              ],
            }}
            canScroll={true}
            minHeight={'700px'}
            maxHeight={'700px'}
          >
            <pre>{JSON.stringify(eventJobsData, null, 2)}</pre>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Card>
        </GridColumn>
      </GridRow>
      <ConfirmationDialog />
    </Grid>
  );
});
