import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page/Page';
import { IconMenu } from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { PlanungAdminRoles } from '@bp/pim-auth-constants';
import { AbsenceIllustration, AnnouncementIllustration, SubstitutionIllustration } from '@bp/ui-components';

export const SubstitutionsPlanPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissionChecker();

  return (
    <Page title={`${t(`substitutions.title`, { count: 2 })} ${t('substitutions.plan').toLowerCase()}`}>
      <IconMenu headline={t('common.whatToPlan')}>
        <IconMenuItem
          label={t('absences.title', { count: 2 })}
          icon={<AbsenceIllustration />}
          bubbleType={7}
          onClick={() => navigate(t('routes.absences.slug'))}
          disabled={
            !(permissions?.isOmniAdmin() || permissions?.hasOrganizationRole(PlanungAdminRoles.VertretungAdmin))
          }
        />
        <IconMenuItem
          label={t(`substitutions.title`, { count: 2 })}
          icon={<SubstitutionIllustration />}
          bubbleType={9}
          onClick={() => navigate(t('routes.substitutions.slug'))}
          disabled={
            !(permissions?.isOmniAdmin() || permissions?.hasOrganizationRole(PlanungAdminRoles.VertretungAdmin))
          }
        />
        <IconMenuItem
          label={t(`announcements.title`, { count: 2 })}
          icon={<AnnouncementIllustration />}
          bubbleType={12}
          onClick={() => navigate(t('routes.announcements.slug'))}
          disabled
        />
      </IconMenu>
    </Page>
  );
};
