import { Modal, TimetableViewEntryType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { MergeEventForm } from '../forms/MergeEventForm';

type MergeEventModalProps = {
  isOpen: boolean;
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  timetableStart: Date;
  timetableEnd: Date;
  timetableEntries: TimetableViewEntryType[];
  originalEntryUuid: string;
  onClose: () => void;
};

export const MergeEventModal = ({
  isOpen,
  originTeachers,
  originRooms,
  eventStart,
  eventEnd,
  timetableStart,
  timetableEnd,
  timetableEntries,
  originalEntryUuid,
  onClose,
}: MergeEventModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal width='s' title={t('substitutions.mergeEvent')} isOpen={isOpen} onRequestClose={onClose}>
      <MergeEventForm
        originTeachers={originTeachers}
        originRooms={originRooms}
        eventStart={eventStart}
        eventEnd={eventEnd}
        timetableStart={timetableStart}
        timetableEnd={timetableEnd}
        timetableEntries={timetableEntries}
        originalEntryUuid={originalEntryUuid}
        onClose={onClose}
      />
    </Modal>
  );
};
