import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { hexToColorOption } from '../../../utils/colorUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import {
  TableColumns,
  Table,
  Row,
  DropdownMenuItem,
  useDefaultSelecting,
  DropdownMenu,
  Dropdown,
  EditIcon,
  Button,
  ImportIcon,
} from '@bp/ui-components';
import { ClassesTableType } from './SettingsClassesTable';
import {
  TimetableReportType,
  use_TimetableBlocksQuery,
  useUpdateClassesAttendancePlanMutation,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { createTimetableCollectiveReport, downloadReport } from '../../../utils/pdfReport';
import dayjs from 'dayjs';
import { showUserErrorToast } from '../../../utils/toast';

type AttendanceClassesTableType = Omit<ClassesTableType, 'grade' | 'gradeGroup' | 'printOrder' | 'printPageNr'> & {
  attendancePlan: boolean;
};

export const AttendanceClassesTable: FC = observer(() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const context = useMemorizedCacheTag('CLASSES_FIXED');
  const timetableBlockContext = useMemorizedCacheTag('TIMETABLE_BLOCK');

  const [, updateAttendancePlan] = useUpdateClassesAttendancePlanMutation();

  const [loading, setLoading] = useState(false);
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('attendance-classes-list', {
    tutor3: false,
    color: false,
    roomName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('attendance-classes-list');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const { classesData, roomsData, teacherData } = useLoadBasicData({ pause: false });

  const today = useMemo(() => {
    return dayjs().startOf('day').toISOString();
  }, []);

  const [{ data }] = use_TimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: { uuid: currentSchoolYear?.uuid },
        start_LTE: today,
        end_GTE: today,
      },
    },
    context: timetableBlockContext,
    pause: !currentSchoolYear,
  });

  const currentTimetableBlockUuid = useMemo(() => {
    return data?.timetableBlocks[0]?.uuid;
  }, [data]);

  function createColumns(): TableColumns<AttendanceClassesTableType>[] {
    return [
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 300,
        canExpand: true,
      },
      {
        header: t('classes.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
        size: 200,
      },
      {
        header: `${t('classes.tutor.short')} 1`,
        meta: {
          filterName: `${t('classes.tutor.full')} 1`,
        },
        size: 250,
        id: 'tutor1',
        accessorKey: 'tutor1',
      },
      {
        header: `${t('classes.tutor.short')} 2`,
        meta: {
          filterName: `${t('classes.tutor.full')} 2`,
        },
        size: 250,
        id: 'tutor2',
        accessorKey: 'tutor2',
      },
      {
        header: `${t('classes.tutor.short')} 3`,
        meta: {
          filterName: `${t('classes.tutor.full')} 3`,
        },
        size: 250,
        id: 'tutor3',
        accessorKey: 'tutor3',
      },
      {
        header: t('lesson.table.rooms'),
        id: 'roomName',
        accessorKey: 'roomName',
        size: 350,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
        size: 100,
      },
      {
        header: t('classes.attendanceClass', { count: 1 }),
        accessorKey: 'attendancePlan',
        id: 'attendancePlan',
        type: 'boolean',
        size: 100,
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  const functionClasses = useMemo((): AttendanceClassesTableType[] => {
    return classesData
      ? classesData.classes
          .filter((c) => !c.grade)
          .map((c) => {
            const roomName = roomsData?.rooms.find((room) => room.uuid === c.defaultRoom?.uuid)?.name ?? '';
            const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
            const tutor1 = teacherData?.people.find((t) => t.uuid === c.tutors[0]?.uuid);
            const tutor2 = teacherData?.people.find((t) => t.uuid === c.tutors[1]?.uuid);
            const tutor3 = teacherData?.people.find((t) => t.uuid === c.tutors[2]?.uuid);

            return {
              uuid: c.uuid,
              name: c.name,
              shortName: c.shortName,
              tutor1: tutor1?.displayNameShort ?? '',
              tutor2: tutor2?.displayNameShort ?? '',
              tutor3: tutor3?.displayNameShort ?? '',
              roomName,
              color: {
                color: html,
                colorLabel: label,
              },
              attendancePlan: c.attendancePlan ?? false,
            };
          })
      : [];
  }, [classesData, roomsData?.rooms, teacherData?.people]);

  async function handleAttendancePlanChange(uuids: string[], active: boolean) {
    await updateAttendancePlan(
      {
        uuids,
        attendancePlan: active,
      },
      context,
    );
  }

  const createBulkEditItems = useCallback((rows: Row<AttendanceClassesTableType>[]): DropdownMenuItem[] => {
    return [
      {
        label: `${t('classes.attendanceClass', { count: 2 })} ${t('common.activate').toLowerCase()}`,
        onClick: async () => {
          await handleAttendancePlanChange(
            rows.map(({ original }) => original.uuid),
            true,
          );
        },
      },
      {
        label: `${t('classes.attendanceClass', { count: 2 })} ${t('common.deactivate').toLowerCase()}`,
        onClick: async () => {
          await handleAttendancePlanChange(
            rows.map(({ original }) => original.uuid),
            false,
          );
        },
      },
    ];
  }, []);

  const hastAttendanceClasses = functionClasses.some((c) => c.attendancePlan);

  return (
    <>
      <Table<AttendanceClassesTableType>
        showBorderRadius
        showShadow
        canScroll
        showSelect
        minHeight={600}
        showVisibility
        breakpoint={null}
        isOnWhite={false}
        sorting={sorting}
        onSortingChange={saveSorting}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        showSort
        columns={tableColumns}
        data={functionClasses}
        bulkEditDropdownContent={(rows) => {
          return <DropdownMenu data={createBulkEditItems(rows)} />;
        }}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('classes.attendanceClass', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('classes.attendanceClass', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showBulkEdit: true,
          showPrintButton: true,
          extendedActionsRight: (
            <Button
              hierarchy={'tertiary'}
              disabled={!currentTimetableBlockUuid || !hastAttendanceClasses}
              isLoading={loading}
              onClick={() => {
                setLoading(true);
                if (currentTimetableBlockUuid) {
                  createTimetableCollectiveReport(
                    currentTimetableBlockUuid,
                    'timetable',
                    TimetableReportType.AttendanceSchedule,
                  )
                    .then(async (value) => {
                      void downloadReport(
                        {
                          uuid: value.uuid,
                          name: `${t('classes.currentAttendanceSchedule', { count: 1 })}-${dayjs().format('YYYY-MM-DD')}`,
                          timestamp: dayjs(),
                        },
                        TimetableReportType.AttendanceSchedule,
                      );
                    })
                    .catch((error) => {
                      showUserErrorToast({ error });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }
              }}
              icon={<ImportIcon />}
            >
              {t('classes.currentAttendanceSchedule', { count: 1 })}
            </Button>
          ),
        }}
        lastCol={(row) => {
          return (
            <Dropdown trigger={<Button hierarchy={'tertiary'} icon={<EditIcon />} />}>
              <DropdownMenu
                data={[
                  {
                    type: 'switch',
                    className: 'pt-2 pb-2',
                    label: t('classes.attendanceClass', { count: 1 }),
                    onValueChange: async () =>
                      await handleAttendancePlanChange([row.original.uuid], !row.original.attendancePlan),
                    value: row.original.attendancePlan,
                  },
                ]}
              />
            </Dropdown>
          );
        }}
      />
    </>
  );
});
