import { Button, ButtonGroup, DeleteIcon, EditIcon, Table, TableColumns } from '@bp/ui-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useAbsencesQuery } from '../../../types/planung-graphql-client-defs';
import { getAbsenceReasonTranslation } from '../../../utils/absenceReasons';
import { useSubstitutionStore } from '../../Substitutions/SubstitutionProvider';
import { observer } from 'mobx-react-lite';

type TeacherAbsencesTableProps = {
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export type TeacherAbsenceTableType = {
  uuid: string;
  teacherName: string;
  start: string;
  end: string;
  reason: string;
  comment: string;
};

export const TeacherAbsencesTable = observer(({ onAdd, onEdit, onDelete }: TeacherAbsencesTableProps) => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('ABSENCES');

  const { selectedDay } = useSubstitutionStore();

  const selectedDayStart = selectedDay.utc().startOf('day').toISOString();
  const selectedDayEnd = selectedDay.utc().endOf('day').toISOString();

  const [{ data }] = useAbsencesQuery({
    variables: {
      where: {
        OR: [
          {
            start_GTE: selectedDayStart,
            end_LTE: selectedDayEnd,
          },
          {
            start_LTE: selectedDayStart,
            end_GTE: selectedDayEnd,
          },
          {
            AND: [
              {
                start_GTE: selectedDayStart,
                start_LTE: selectedDayEnd,
              },
            ],
          },
        ],
      },
    },
    context,
    pause: !selectedDay,
  });

  const absences: TeacherAbsenceTableType[] =
    data?.absences.map((a) => {
      return {
        uuid: a.uuid,
        teacherName: a.person?.listName ?? '',
        start: dayjs(a.start).utc().format('DD.MM.YYYY HH:mm'),
        end: dayjs(a.start).utc().isSame(a.end, 'day')
          ? dayjs(a.end).utc().format('HH:mm')
          : dayjs(a.end).utc().format('DD.MM.YYYY HH:mm'),
        reason: getAbsenceReasonTranslation(a.reason),
        comment: a.comment ?? '',
      };
    }) ?? [];

  const columns: TableColumns<TeacherAbsenceTableType>[] = [
    {
      header: t('persons.titleNeutral'),
      id: 'teacherName',
      accessorKey: 'teacherName',
      size: 250,
    },
    {
      header: t('common.start'),
      id: 'start',
      accessorKey: 'start',
    },
    {
      header: t('common.end'),
      id: 'end',
      accessorKey: 'end',
    },
    {
      header: t('absences.reason'),
      id: 'reason',
      accessorKey: 'reason',
      size: 150,
    },
    {
      header: t('common.comment'),
      id: 'comment',
      accessorKey: 'comment',
      size: 250,
    },
  ];

  return (
    <Table<TeacherAbsenceTableType>
      columns={columns}
      data={absences}
      showShadow
      showBorderRadius
      showActionBar
      actionBarSettings={{ showAddButton: true }}
      onAddClick={onAdd}
      isOnWhite={false}
      lastColWidth='80px'
      lastCol={(row) => {
        return (
          <ButtonGroup>
            <Button
              hierarchy='secondary'
              onClick={() => onEdit(row.original.uuid)}
              disabled={true}
              icon={<EditIcon className='small' />}
            />
            <Button
              hierarchy='secondary'
              onClick={() => onDelete(row.original.uuid)}
              icon={<DeleteIcon className='small' color='var(--color-error)' />}
            />
          </ButtonGroup>
        );
      }}
    />
  );
});
