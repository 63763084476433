import { Outlet } from 'react-router-dom';
import { SubstitutionsTopBar } from '../../../components/SubstitutionsTopBar/SubstitutionsTopBar';
import { Page } from '../../../components/Page/Page';
import { Suspense } from 'react';
import { LazyLoader } from '@bp/ui-components';

type PlanSubstitutionsProps = {
  baseSlug: string;
};

export const SubstitutionsPlanSubpage = ({ baseSlug }: PlanSubstitutionsProps) => {
  return (
    <Page title={null}>
      <SubstitutionsTopBar baseSlug={baseSlug} />
      <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
