import { useState } from 'react';
import { TeacherAbsencesTable } from '../../../../components/Absences/tables/TeacherAbsencesTable';
import { TeacherAbsenceModal } from '../../../../components/Absences/modals/TeacherAbsenceModal';
import { AbsenceReason } from '../../../../utils/absenceReasons';
import { useDeleteAbsenceMutation } from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTags } from '../../../../hooks/useMemorizedCacheTag';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../../../hooks/useConfirm';
import { showErrorToast, showSuccessCreateToast } from '../../../../utils/toast';

export type TeacherAbsence = {
  uuid: string;
  teacherUuid: string;
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  reason: AbsenceReason;
  comment: string;
};

export const PlanTeacherAbsences = () => {
  const { t } = useTranslation();
  const deleteAbsenceContext = useMemorizedCacheTags(['ABSENCES', 'EVENT']);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedAbsence, setSelectedAbsence] = useState<TeacherAbsence | null>(null);

  const [, remove] = useDeleteAbsenceMutation();

  const { confirm: confirmDelete, ConfirmationDialog } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('absences.title', { count: 1 }), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  async function handleEdit(uuid: string) {
    // TODO: implement
  }

  async function handleDelete(uuid: string) {
    const { error } = await remove(
      {
        input: {
          uuids: [uuid],
        },
      },
      deleteAbsenceContext,
    );
    if (error) {
      showErrorToast(error);
    } else {
      showSuccessCreateToast();
    }
  }

  return (
    <>
      <TeacherAbsencesTable
        onAdd={() => setIsModalOpen(true)}
        onEdit={handleEdit}
        onDelete={async (uuid: string) => {
          const res = await confirmDelete();
          if (res) {
            await handleDelete(uuid);
          }
        }}
      />

      <TeacherAbsenceModal
        selectedAbsence={selectedAbsence ?? undefined}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedAbsence(null);
        }}
      />
      <ConfirmationDialog />
    </>
  );
};
