import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { IconMenu } from '../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page/Page';
import { BasedataIcon, CalendarIcon, UpdateIcon } from '@bp/ui-components';

export const AdminPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={t('routes.admin.title')}>
      <IconMenu headline={t('dashboard.maintain')}>
        <IconMenuItem
          icon={<BasedataIcon />}
          label={t('organizations.title', { count: 2 })}
          onClick={() => navigate(t('routes.organizations.slug'))}
          bubbleType={5}
        />
        <IconMenuItem
          icon={<UpdateIcon />}
          label={t('routes.mathplan.title')}
          onClick={() => navigate(t('routes.mathplan.slug'))}
          bubbleType={13}
        />
        <IconMenuItem
          icon={<CalendarIcon />}
          label={t('routes.events.title')}
          onClick={() => navigate(t('routes.events.slug'))}
          bubbleType={9}
        />
      </IconMenu>
    </Page>
  );
};
