import { apiHelper } from './apiHelper';

export const deleteEvents = async ({
  organizationUuid,
  schoolYearUuid,
}: {
  organizationUuid: string;
  schoolYearUuid: string;
}) => {
  await apiHelper<void>({
    method: 'DELETE',
    path: `/events/${organizationUuid}/${schoolYearUuid}`,
  });
};

export const createEvents = async ({
  organizationUuid,
  schoolYearUuid,
}: {
  organizationUuid: string;
  schoolYearUuid: string;
}) => {
  await apiHelper<void>({
    method: 'POST',
    path: `/events/${organizationUuid}/${schoolYearUuid}`,
  });
};
