import { SortDirection } from '@bp/planung-graphql-types';
import { Button, DotsHorizontalIcon, Dropdown, DropdownMenu, Table, TableColumns, Tooltip } from '@bp/ui-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { use_SchoolEventsQuery } from '../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useMemo } from 'react';

export type SchoolEventTableType = {
  uuid: string;
  name: string;
  classes: string;
  groups: string;
  subject: string;
  teachers: string;
  start: string;
  end: string;
};

type SchoolEventTableProps = {
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDuplicate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export const SchoolEventTable = ({ onAdd, onEdit, onDuplicate, onDelete }: SchoolEventTableProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const context = useMemorizedCacheTag('EVENT');

  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ start: SortDirection.Desc }],
      },
      where: {
        isSchoolEvent: true,
        OR: [
          { start_GTE: currentSchoolYear?.start, end_LTE: currentSchoolYear?.end },
          {
            start_LTE: currentSchoolYear?.start,
            end_GTE: currentSchoolYear?.end,
          },
        ],
      },
    },
    context: context,
  });

  const { classesData, subjectData, teacherData, groupsData, divisionsData } = useLoadBasicData({
    pause: !eventData,
  });

  const data: SchoolEventTableType[] = useMemo(() => {
    return (
      eventData?.events.map((event) => {
        const subject = subjectData?.subjects.find((s) => s.uuid === event.subject.uuid);
        return {
          uuid: event.uuid,
          name: event.comment ?? '',
          groups:
            event.classesOrGroups
              .filter((ec) => ec.__typename === 'Group')
              .map((ec) => {
                const group = groupsData?.groups.find((g) => g.uuid === ec.uuid);

                const division = divisionsData?.divisions.find(
                  (d) => d.uuid === group?.divisionConnection.edges[0].node.uuid,
                );

                return `${group?.shortName} (${division?.class?.shortName}) `;
              })
              .sort()
              .join(', ') ?? '',
          classes:
            classesData?.classes
              .filter((c) => event.classesOrGroups.map((ec) => ec.uuid).includes(c.uuid))
              .map((c) => c.name)
              .join(', ') ?? '',
          subject: subject?.name ?? '',
          teachers: event.personsConnection.edges
            .map((edge) => {
              return teacherData?.people.find((t) => t.uuid === edge.node.uuid)?.displayNameShort ?? '';
            })
            .join(', '),
          start: dayjs(event.start).format('DD.MM.YYYY HH:mm'),
          end: dayjs(event.start).isSame(event.end, 'day')
            ? dayjs(event.end).format('HH:mm')
            : dayjs(event.end).format('DD.MM.YYYY HH:mm'),
        };
      }) ?? []
    );
  }, [
    eventData?.events,
    classesData?.classes,
    divisionsData?.divisions,
    groupsData?.groups,
    subjectData?.subjects,
    teacherData?.people,
  ]);

  const tooltipFix = { maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', width: 'fit-content' };

  const columns: TableColumns<SchoolEventTableType>[] = [
    {
      header: t('common.name'),
      accessorKey: 'name',
      id: 'name',
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.name}>
            {row.original.name}
          </Tooltip>
        );
      },
      size: 250,
    },
    {
      header: `${t('classes.title', { count: 2 })}/${t('groups.title', { count: 2 })}`,
      accessorKey: 'classesAndGroups',
      id: 'classesAndGroups',
      cell: ({ row }) => {
        const classes = row.original.classes;
        const groups = row.original.groups;
        const content =
          classes && groups
            ? classes + ', ' + groups
            : classes && !groups
              ? classes
              : !classes && groups
                ? groups
                : '---';

        return (
          <Tooltip triggerStyle={tooltipFix} content={content}>
            {content}
          </Tooltip>
        );
      },
      size: 250,
    },
    {
      header: t('subject.title', { count: 1 }),
      accessorKey: 'subject',
      id: 'subject',
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.subject}>
            {row.original.subject}
          </Tooltip>
        );
      },
      size: 250,
    },
    {
      header: t('persons.title', { count: 2 }),
      accessorKey: 'teachers',
      id: 'teachers',
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.teachers}>
            {row.original.teachers}
          </Tooltip>
        );
      },
      size: 250,
    },
    {
      header: t('common.from'),
      accessorKey: 'start',
      id: 'start',
    },
    {
      header: t('common.until'),
      accessorKey: 'end',
      id: 'end',
    },
  ];

  return (
    <Table<SchoolEventTableType>
      isOnWhite={false}
      showBorderRadius
      showShadow
      showActionBar
      actionBarSettings={{ showExpertFilter: true, showAddButton: true, addButtonText: t('common.add') }}
      onAddClick={() => onAdd()}
      emptyStateSettings={{ title: t('schoolEvents.noEvents') }}
      columns={columns}
      data={data}
      lastColWidth='80px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button icon={<DotsHorizontalIcon />} hierarchy='tertiary' />}>
            <DropdownMenu
              data={[
                {
                  label: t('common.edit'),
                  onClick: () => onEdit(row.original.uuid),
                  disabled: true,
                },
                {
                  label: t('common.duplicate'),
                  onClick: () => onDuplicate(row.original.uuid),
                  disabled: true,
                },
                {
                  type: 'ruler',
                },
                {
                  label: t('common.delete'),
                  onClick: () => onDelete(row.original.uuid),
                  color: 'error',
                },
              ]}
            />
          </Dropdown>
        );
      }}
    />
  );
};
