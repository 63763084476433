import styles from './SubstitutionForm.module.scss';
import { EmptyState, Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  SubstitutionFormRow,
  SubstitutionFormRowEventType,
  SubstitutionFormRowStatus,
  SubstitutionFormRowType,
} from './SubstitutionFormRow';
import { useTranslation } from 'react-i18next';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  getMinutesDifference,
  isFirstSameOrAfterSecond,
  isFirstSameOrBeforeSecond,
  normalizedDate,
} from '../../../utils/dateCalculations';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { useSubstitutionStore } from '../SubstitutionProvider';
import { SubstitutionEventsTableType } from '../tables/SubstitutionEventsTable';
import { Form, Formik, FormikHelpers } from 'formik';
import { SubstitutionRoomsSelect } from '../components/SubstitutionRoomsSelect';
import { use_UpdateSingleEventMutation } from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

type SubstitutionFormProps = {
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  eventUuid: string;
  timetableStart: Date;
  timetableEnd: Date;
  events: SubstitutionEventsTableType[];
  onClose: () => void;
};

export type SubstitutionFormType = {
  roomUuids: string[];
  comment: string;
  substitutionRows: SubstitutionFormRowType[];
};

export const SubstitutionForm = ({
  originTeachers,
  originRooms,
  eventStart,
  eventEnd,
  eventUuid,
  timetableStart,
  timetableEnd,
  events,
  onClose,
}: SubstitutionFormProps) => {
  const { t } = useTranslation();
  const { selectedDay } = useSubstitutionStore();
  const { teacherData } = useLoadBasicData({ pause: false });
  const [, updateSingleEvent] = use_UpdateSingleEventMutation();
  const eventContext = useMemorizedCacheTag('EVENT');

  const currentEvent = events.find((e) => e.uuid === eventUuid);

  const getName = useCallback(
    (status: SubstitutionFormRowStatus) => {
      switch (status) {
        case 'normal':
          return t('substitutions.normalLesson');
        case 'attendance':
          return t('substitutions.attendance');
        case 'cancelation-class-trip':
          return t('substitutions.cancellationClassTrip');
        case 'substitution':
          return t('substitutions.title', { count: 1 });
        case 'on-class-trip':
          return t('substitutions.onClassTrip');
        case 'signed-out':
          return t('substitutions.signedOut');
        default:
          return t('substitutions.normalLesson');
      }
    },
    [t],
  );

  const rows: SubstitutionFormRowType[] = useMemo(() => {
    const rows: SubstitutionFormRowType[] = [];

    // iterate teachers of school
    teacherData?.people.forEach((teacher) => {
      const teacherEvents: SubstitutionFormRowEventType[] = [];
      let rowStatus: SubstitutionFormRowStatus = 'normal';
      let fullDay: boolean = false;
      let givenSubstituteHours: number | null = null;

      // iterate events for day and find events by status for teacher
      for (const event of events) {
        const eventTeacher = event.teachers.find((t) => t.uuid === teacher.uuid);
        const missingTeacher = event.missingTeachers.find((t) => t.uuid === teacher.uuid);
        const substituteTeacher = event.substitutionTeachers.find((t) => t.uuid === teacher.uuid);

        const teacherEvent = {
          eventUuid: event.uuid,
          start: event.start,
          end: event.end,
          subjectShortName: event.subject.shortName,
          subjectName: event.subject.name,
          classOrGroupNames: [...event.classes, ...event.groups].map((g) => g.name).join(', '),
        };

        if (substituteTeacher) {
          givenSubstituteHours =
            event.uuid === currentEvent?.uuid ? substituteTeacher.givenSubstituteHours : givenSubstituteHours;
          teacherEvents.push({
            ...teacherEvent,
            name: getName(substituteTeacher.status),
            eventStatus: substituteTeacher.status,
          });
        } else if (missingTeacher) {
          rowStatus = missingTeacher.status;
          fullDay = missingTeacher.fullDay;
          teacherEvents.push({
            ...teacherEvent,
            name: getName(missingTeacher.status),
            eventStatus: missingTeacher.status,
          });
        } else if (eventTeacher) {
          teacherEvents.push({
            ...teacherEvent,
            name: getName(eventTeacher.status),
            eventStatus: eventTeacher.status,
          });
        }
      }

      rows.push({
        disabled: currentEvent?.missingTeachers.some((t) => t.uuid === teacher.uuid) ?? false,
        teacherUuid: teacher.uuid,
        teacherName: teacher.displayName,
        selected: !!currentEvent?.substitutionTeachers.find((t) => t.uuid === teacher.uuid),
        givenSubstituteHours: givenSubstituteHours,
        target: 0,
        current: 0,
        sameClass: false,
        sameSubject: false,
        events: teacherEvents,
        rowStatus: rowStatus,
        fullDay: fullDay,
      });
    });

    return rows;
  }, [currentEvent?.teachers, events, getName, teacherData?.people]);

  const initialValues: SubstitutionFormType = {
    roomUuids: [],
    comment: '',
    substitutionRows: rows,
  };

  const hourRef = useRef<HTMLDivElement>(null);
  const [hourWidth, setHourWidth] = useState<number>(0);

  const startHour = dayjs(timetableStart).hour();
  const endHour = dayjs(timetableEnd).hour();
  const hours = Array.from({ length: endHour - startHour + 1 }, (_, i) =>
    dayjs()
      .set('hour', startHour + i)
      .startOf('hour'),
  );

  const frames: { left: number; width: number }[] = [];

  const normalizedEventStart = normalizedDate(eventStart, selectedDay);
  const normalizedEventEnd = normalizedDate(eventEnd, selectedDay);

  const isBefore = isFirstSameOrBeforeSecond(normalizedEventStart, timetableStart, 'minutes');
  const isAfter = isFirstSameOrAfterSecond(normalizedEventEnd, timetableEnd, 'minutes');

  const subjectDuration = getMinutesDifference(normalizedEventStart, normalizedEventEnd);
  const subjectWidth = (hourWidth / 60) * subjectDuration;

  const startDuration = isBefore ? 0 : getMinutesDifference(normalizedEventStart, timetableStart);
  const startWidth = (hourWidth / 60) * startDuration;

  const endDuration = isAfter ? 0 : getMinutesDifference(normalizedEventEnd, timetableEnd);
  const endWidth = (hourWidth / 60) * endDuration;

  frames.push(
    {
      left: 0,
      width: startWidth,
    },
    {
      left: startWidth + subjectWidth,
      width: endWidth,
    },
  );

  async function handleSubmit(values: SubstitutionFormType, formHelpers: FormikHelpers<SubstitutionFormType>) {
    const selectedTeachers = values.substitutionRows.filter((r) => r.selected);
    const disconnectTeachers = !selectedTeachers
      ? currentEvent?.teachers
      : currentEvent?.teachers.filter((t) => !selectedTeachers.find((st) => st.teacherUuid === t.uuid));

    await updateSingleEvent(
      {
        uuid: eventUuid,
        update: {
          rooms: {
            connect: values.roomUuids,
            disconnect: currentEvent?.rooms
              .filter((r) => {
                return !values.roomUuids.includes(r.uuid);
              })
              .map((r) => r.uuid),
          },
          comment: values.comment,
          persons: {
            connect: selectedTeachers.map((t) => {
              return {
                uuid: t.teacherUuid,
                givenSubstituteHours: t.givenSubstituteHours,
                isCoveringTeacher: true,
              };
            }),
            disconnect:
              disconnectTeachers?.map((t) => {
                return t.uuid;
              }) ?? undefined, // no empty array !
          },
        },
      },
      eventContext,
    );
    formHelpers.resetForm();
    onClose();
  }

  useLayoutEffect(() => {
    if (hourRef.current) {
      setHourWidth(hourRef.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form className={styles['substitution-form']}>
            <div className={styles.info}>
              <div>
                {t('substitutions.initialTeacher')}:
                <span className={styles.bold}>
                  {originTeachers.length > 0 ? originTeachers.map((t) => t.name).join(', ') : t('common.none')}
                </span>
              </div>
              <div>
                {t('substitutions.initialRooms')}:
                <span className={styles.bold}>
                  {originRooms.length > 0 ? originRooms.map((r) => r.name).join(', ') : t('common.none')}
                </span>
              </div>
            </div>

            <Grid useFormGap>
              <GridRow spacingBottom='none'>
                <GridColumn width={6}>
                  <SubstitutionRoomsSelect
                    eventRoomUuids={originRooms.map((r) => r.uuid)}
                    eventStart={eventStart}
                    eventEnd={eventEnd}
                    onChange={async (uuids) => await setFieldValue('roomUuids', uuids)}
                    error={errors.roomUuids as string}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    name='comment'
                    value={values.comment}
                    label={t('common.comment')}
                    onChange={async (e) => {
                      await setFieldValue('comment', e.target.value);
                    }}
                    error={errors.comment}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <div className={styles.substitutions}>
              <div className={styles.header}>
                <div className={styles.teachers}>
                  <div className={styles.tiny}></div>
                  <div className={styles.big}>{t('persons.title', { count: 1 })}</div>
                  <div className={styles.small}>{t('common.value')}</div>
                  <div className={styles.small}>{t('substitutions.target')}</div>
                  <div className={styles.small}>{t('substitutions.current')}</div>
                  <div className={styles.medium}>{t('substitutions.sameClass')}</div>
                  <div className={styles.medium}>{t('substitutions.sameLesson')}</div>
                </div>
                <div className={styles.hours} style={{ gridTemplateColumns: `repeat(${hours.length}, 1fr)` }}>
                  {hours.map((hour, index) => {
                    return (
                      <div
                        key={hour.toString()}
                        ref={index === 0 ? hourRef : undefined}
                        className={styles.hour}
                      >{`${String(hour.hour()).padStart(2, '0')}:00`}</div>
                    );
                  })}
                </div>
              </div>

              <div className={classNames(styles.rows, 'has-scrollbar')}>
                {values.substitutionRows ? (
                  values.substitutionRows.map((substitutionRow, index) => {
                    return (
                      <SubstitutionFormRow
                        key={substitutionRow.teacherUuid}
                        hours={hours}
                        frames={frames}
                        hourWidth={hourWidth}
                        row={substitutionRow}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <EmptyState hideIcon title={t('substitutions.noTeachers')} forcedHeight='10vh' />
                )}
              </div>
            </div>

            <div className={styles.footer}>
              <div className={styles.legend}>
                <div className={styles.colors}>
                  <div>{getName('normal')}</div>
                  <div>{getName('attendance')}</div>
                  <div>{getName('cancelation-class-trip')}</div>
                  <div>{getName('substitution')}</div>
                  <div>{getName('on-class-trip')} *</div>
                  <div>{getName('signed-out')} *</div>
                </div>
                <div className={styles.hint}>* = {t('substitutions.fullDay')}</div>
              </div>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    onClose();
                    resetForm();
                  },
                }}
                submitButton={{
                  disabled: isSubmitting || !dirty,
                }}
                className={styles.bottom}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
