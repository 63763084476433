import { Form, Formik, FormikHelpers } from 'formik';
import { EventRepetitions, SchoolEventType } from '../../pages/Substitutions/Setup/SchoolEvent/SetupSchoolEvent';
import {
  Checkbox,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import dayjs from 'dayjs';
import { lowercaseFirstLetter } from '../../utils/helper';
import { MultiValue, SingleValue } from 'react-select';
import {
  getDaysDifference,
  isFirstAfterSecond,
  isFirstBeforeSecond,
  isSame,
  setTimeOnDate,
} from '../../utils/dateCalculations';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import {
  use_CreateSingleSchoolEventMutation,
  use_SchoolEventsQuery,
  use_UpdateSingleEventMutation,
} from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { showSuccessCreateToast, showUserErrorToast } from '../../utils/toast';

export type SchoolEventFormProps = {
  eventUuid?: string | null;
  onLoading: (loading: boolean) => void;
  onClose: () => void;
};

export const SchoolEventForm = ({ eventUuid, onLoading, onClose }: SchoolEventFormProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);

  const context = useMemorizedCacheTag('EVENT');

  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      where: {
        uuid: eventUuid,
      },
    },
    context: context,
  });

  const [, createSingleEvent] = use_CreateSingleSchoolEventMutation();
  const [, updateSingleEvent] = use_UpdateSingleEventMutation();

  const currentEvent = eventData?.events[0];

  const initialValues: SchoolEventType = useMemo(() => {
    return {
      uuid: currentEvent?.uuid ?? '',
      name: currentEvent?.comment ?? '',
      classUuids:
        currentEvent?.classesOrGroups
          .map((c) => {
            return c.__typename === 'Class' ? c.uuid : '';
          })
          .filter((c) => c !== '') ?? [],
      groupUuids:
        currentEvent?.classesOrGroups
          .map((c) => {
            return c.__typename === 'Group' ? c.uuid : '';
          })
          .filter((c) => c !== '') ?? [],
      subjectUuid: currentEvent?.subject.uuid ?? '',
      teacherUuids: currentEvent?.personsConnection.edges.map((e) => e.node.uuid) ?? [],
      startDateTime: currentEvent?.start ?? dayjs().startOf('day').toDate(),
      endDateTime: currentEvent?.end ?? dayjs().endOf('day').toDate(),
      repetition: null,
      lastRepetition: new Date(),
      endTime: '',
      startTime: '',
      isClassbookRelevant: currentEvent?.isClassbookRelevant ?? false,
    };
  }, [
    currentEvent?.classesOrGroups,
    currentEvent?.comment,
    currentEvent?.end,
    currentEvent?.isClassbookRelevant,
    currentEvent?.personsConnection.edges,
    currentEvent?.start,
    currentEvent?.subject.uuid,
    currentEvent?.uuid,
  ]);

  const { classesData, groupsData, divisionsData, teacherData, subjectData } = useLoadBasicData({ pause: false });

  const activeTeachers = teacherData?.people.filter((p) => p.active);

  const classesOptions = useCreateSelectOptions(classesData?.classes, 'uuid', 'name');
  const subjectOptions = useCreateSelectOptions(subjectData?.subjects, 'uuid', 'name');
  const teacherOptions = useCreateSelectOptions(activeTeachers, 'uuid', 'listName');
  const repetitionOptions: SelectOptionType[] = [
    {
      label: t('common.repetition.daily'),
      value: 'daily',
    },
    {
      label: t('common.repetition.weekdays'),
      value: 'weekdays',
    },
    {
      label: t('common.repetition.weekly'),
      value: 'weekly',
    },
    {
      label: t('common.repetition.annually'),
      value: 'annually',
    },
  ];

  const filteredGroupOptions: SelectOptionType[] = useMemo(() => {
    const options: SelectOptionType[] = [];
    const classes = classesData?.classes.filter((c) => selectedClasses.includes(c.uuid));

    classes?.forEach((c) => {
      const divisions = divisionsData?.divisions.filter((d) => c.divisions.map((d) => d.uuid).includes(d.uuid));
      const groups = groupsData?.groups.filter((g) =>
        divisions?.flatMap((d) => d.groupsConnection.edges.map((gc) => gc.node.uuid)).includes(g.uuid),
      );
      groups?.forEach((g) => {
        options.push({
          label: `${g.name} (${c.name})`,
          value: g.uuid,
        });
      });
    });

    return options;
  }, [classesData?.classes, divisionsData?.divisions, groupsData?.groups, selectedClasses]);

  useEffect(() => {
    setSelectedClasses(initialValues.classUuids);
  }, [initialValues.classUuids]);

  function validateRepitition(
    repetition: EventRepetitions | null,
    start: Date | null,
    end: Date | null,
  ): string | undefined {
    let result: string | undefined = undefined;
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    if (repetition === 'daily' && !isSame(startDate, endDate)) {
      result = t('validation.repetition.notDaily');
    } else if (repetition === 'weekdays') {
      if (getDaysDifference(startDate, endDate) > 5) {
        result = t('validation.repetition.moreThanWorkweek');
      } else if (startDate.day() !== 1) {
        result = t('validation.repetition.startNotMonday');
      } else if (endDate.day() !== 5) {
        result = t('validation.repetition.endNotFriday');
      }
    } else if (repetition === 'weekly' && getDaysDifference(startDate, endDate, false) > 7) {
      result = t('validation.repetition.moreThanWeek');
    }

    return result;
  }

  function validateLastRepitition(
    repetition: EventRepetitions | null,
    end: Date | null,
    last: Date | null,
  ): string | undefined {
    let result: string | undefined = undefined;

    if (repetition && isFirstBeforeSecond(last, end)) {
      result = t('validation.repetition.lastBeforeEnd');
    }

    return result;
  }

  async function handleSubmit(values: SchoolEventType, formHelpers: FormikHelpers<SchoolEventType>) {
    onLoading(true);

    if (!values.repetition) {
      if (!eventUuid) {
        const res = await createSingleEvent(
          {
            input: {
              comment: values.name,
              start: values.startDateTime,
              end: values.endDateTime,
              classes: values.classUuids,
              groups: values.groupUuids,
              rooms: [],
              subject: values.subjectUuid,
              isSchoolEvent: true,
              persons: values.teacherUuids,
              isClassbookRelevant: values.isClassbookRelevant,
            },
          },
          context,
        );
        if (res.error) {
          showUserErrorToast({ error: res.error });
        } else {
          showSuccessCreateToast();
        }
      } else {
        const currentClasses =
          currentEvent?.classesOrGroups.filter((c) => c.__typename === 'Class').map((c) => c.uuid) ?? [];
        const currentGroups =
          currentEvent?.classesOrGroups.filter((c) => c.__typename === 'Group').map((c) => c.uuid) ?? [];

        const classesToRemove = currentClasses.filter((c) => !values.classUuids.includes(c));
        const groupsToRemove = currentGroups.filter((g) => !values.groupUuids.includes(g));

        const classesToAdd = values.classUuids.filter((c) => !currentClasses.includes(c));
        const groupsToAdd = values.groupUuids.filter((g) => !currentGroups.includes(g));

        const personsToRemove = currentEvent?.personsConnection.edges
          .map((edge) => edge.node.uuid)
          .filter((p) => !values.teacherUuids.includes(p));
        const personsToAdd = values.teacherUuids;

        const res = await updateSingleEvent(
          {
            uuid: values.uuid,
            update: {
              print: false,
              comment: values.name,
              start: values.startDateTime,
              end: values.endDateTime,
              classes: {
                disconnect: classesToRemove.filter((c) => !groupsToAdd.includes(c)),
                connect: classesToAdd,
              },
              groups: {
                disconnect: groupsToRemove.filter((g) => !classesToAdd.includes(g)),
                connect: groupsToAdd,
              },
              subject: values.subjectUuid,
              persons: {
                disconnect: personsToRemove,
                connect: personsToAdd.map((p) => {
                  return {
                    uuid: p,
                    givenSubstituteHours: 0,
                    isCoveringTeacher: false,
                  };
                }),
              },
              isClassbookRelevant: values.isClassbookRelevant,
            },
          },
          context,
        );
        if (res.error) {
          showUserErrorToast({ error: res.error });
        } else {
          showSuccessCreateToast();
        }
      }
    }

    onLoading(false);
    onClose();
  }

  const validate = (values: SchoolEventType) => {
    const errors: Record<string, string> = {};
    if (!values.subjectUuid || values.subjectUuid === '') {
      errors.subjectUuid = t('validation.common.required');
    }
    if (isFirstAfterSecond(values.startDateTime, values.endDateTime, 'day')) {
      errors.startDateTime = t('validation.common.startDateAfterEnd');
      errors.endDateTime = t('validation.common.startDateAfterEnd');
    }
    if (
      isSame(values.startDateTime, values.endDateTime, 'day') &&
      isFirstAfterSecond(values.startDateTime, values.endDateTime, 'minute')
    ) {
      errors.startTime = t('validation.common.startTimeAfterEnd');
      errors.endTime = t('validation.common.startTimeAfterEnd');
    }
    if (values.name.length > 20) {
      errors.name = t('validation.common.maxLength', { length: 20 });
    }
    return errors;
  };

  return (
    <Formik<SchoolEventType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors, setFieldError }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow>
                <GridColumn width={10}>
                  <GridRow spacingBottom='s'>
                    <Input
                      name='name'
                      value={values.name}
                      label={t('common.name')}
                      onChange={async (e) => await setFieldValue('name', e.target.value)}
                      error={errors.name}
                    />
                  </GridRow>
                  <GridRow spacingTop='s' spacingBottom='s'>
                    <GridColumn width={6}>
                      <Select
                        name='subject'
                        isClearable
                        error={errors.subjectUuid}
                        isSearchable
                        menuPosition='fixed'
                        label={t('subject.title', { count: 1 })}
                        options={subjectOptions}
                        value={subjectOptions?.find((s) => s.value === values.subjectUuid)}
                        onChange={async (option) => {
                          const opt = option as SingleValue<SelectOptionType>;
                          await setFieldValue('subjectUuid', opt?.value ?? null);
                        }}
                        required
                      />
                    </GridColumn>
                    <GridColumn width={6}>
                      <Select
                        name='teachers'
                        isMulti
                        isClearable
                        isSearchable
                        menuPosition='fixed'
                        label={t('persons.title', { count: 1 })}
                        options={teacherOptions}
                        value={teacherOptions?.filter((t) => values.teacherUuids.includes(t.value as string))}
                        onChange={async (options) => {
                          const opt = options as MultiValue<SelectOptionType>;
                          await setFieldValue(
                            'teacherUuids',
                            opt.map((o) => o.value),
                          );
                        }}
                        required
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s'>
                    <GridColumn width={6}>
                      <Select
                        name='classes'
                        isMulti
                        isSearchable
                        isClearable
                        menuPosition='fixed'
                        label={t('classes.title', { count: 1 })}
                        options={classesOptions}
                        value={classesOptions?.filter((c) => values.classUuids.includes(c.value as string))}
                        onChange={async (e) => {
                          const opt = e as MultiValue<SelectOptionType>;
                          await setFieldValue(
                            'classUuids',
                            opt.map((o) => o.value),
                          );
                          setSelectedClasses(opt.map((o) => o.value as string));
                        }}
                      />
                    </GridColumn>
                    <GridColumn width={6}>
                      <Select
                        name='groups'
                        isMulti
                        isSearchable
                        isClearable
                        menuPosition='fixed'
                        label={t('groups.title', { count: 1 })}
                        options={filteredGroupOptions}
                        value={filteredGroupOptions.filter((g) => values.groupUuids.includes(g.value as string))}
                        onChange={async (e) => {
                          const opt = e as MultiValue<SelectOptionType>;
                          await setFieldValue(
                            'groupUuids',
                            opt.map((o) => o.value),
                          );
                        }}
                        disabled={filteredGroupOptions.length === 0}
                        placeholder={lowercaseFirstLetter(t('schoolEvents.noClassesHint'))}
                      />
                    </GridColumn>
                  </GridRow>
                </GridColumn>
                <GridColumn width={2} align='end'>
                  <Checkbox
                    className='mt-3'
                    name='classbookRelevant'
                    label={t('schoolEvents.classbookRelevant')}
                    checked={values.isClassbookRelevant}
                    onChange={async (e) => await setFieldValue('isClassbookRelevant', e.target.checked)}
                  />
                </GridColumn>
              </GridRow>
              <GridRow headline={t('common.timeFrame')}>
                <GridColumn width={10}>
                  <GridRow spacingBottom='s'>
                    <GridColumn width={3}>
                      <DatePicker
                        name='startDateTime'
                        value={values.startDateTime}
                        label={t('common.from')}
                        onChange={async (e) => {
                          await setFieldValue('startDateTime', e);
                        }}
                        minDate={dayjs(currentSchoolYear?.start).toDate()}
                        maxDate={dayjs(currentSchoolYear?.end).toDate()}
                        error={errors.startDateTime as string}
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <Input
                        name='startTime'
                        label={t('common.start')}
                        value={dayjs(values.startDateTime).format('HH:mm')}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          const time = setTimeOnDate(values.startDateTime, event.target.value);
                          await setFieldValue('startDateTime', time);
                        }}
                        error={errors.startTime as string}
                        type='time'
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <DatePicker
                        name='endDateTime'
                        value={values.endDateTime}
                        label={t('common.until')}
                        onChange={async (e) => {
                          await setFieldValue('endDateTime', e);
                        }}
                        minDate={dayjs(currentSchoolYear?.start).toDate()}
                        maxDate={dayjs(currentSchoolYear?.end).toDate()}
                        error={errors.endDateTime as string}
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <Input
                        name='endTime'
                        label={t('common.end')}
                        value={dayjs(values.endDateTime).format('HH:mm')}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          const time = setTimeOnDate(values.endDateTime, event.target.value);
                          await setFieldValue('endDateTime', time);
                        }}
                        error={errors.endTime as string}
                        type='time'
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s'>
                    <GridColumn width={6}>
                      <Select
                        disabled={true}
                        name='repetition'
                        placeholder={t('common.none')}
                        isClearable
                        label={t('schoolEvents.repetition')}
                        options={repetitionOptions}
                        onChange={async (e) => {
                          await setFieldValue('repetition', e);
                          setFieldError(
                            'repetition',
                            validateRepitition(
                              e ? ((e as SelectOptionType).value as EventRepetitions) : null,
                              values.startDateTime,
                              values.endDateTime,
                            ),
                          );
                          setFieldError(
                            'lastRepetition',
                            validateLastRepitition(
                              e ? ((e as SelectOptionType).value as EventRepetitions) : null,
                              values.endDateTime,
                              values.lastRepetition,
                            ),
                          );
                        }}
                        error={errors.repetition as string}
                        menuPosition='fixed'
                      />
                    </GridColumn>
                    <GridColumn width={6}>
                      <DatePicker
                        name='lastRepetition'
                        label={t('schoolEvents.lastRepetition')}
                        onChange={async (e) => {
                          await setFieldValue('lastRepetition', e);
                          setFieldError(
                            'repetition',
                            validateRepitition(
                              values.repetition
                                ? ((values.repetition as unknown as SelectOptionType).value as EventRepetitions)
                                : null,
                              values.startDateTime,
                              values.endDateTime,
                            ),
                          );
                          setFieldError(
                            'lastRepetition',
                            validateLastRepitition(
                              values.repetition
                                ? ((values.repetition as unknown as SelectOptionType).value as EventRepetitions)
                                : null,
                              values.endDateTime,
                              e,
                            ),
                          );
                        }}
                        disabled={true}
                        error={errors.lastRepetition as string}
                      />
                    </GridColumn>
                  </GridRow>
                </GridColumn>
                <GridColumn width={2}>
                  <></>
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              submitButton={{
                disabled: isSubmitting || !dirty,
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
