import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { WorkOrderForm } from '../forms/WorkOrderForm';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';

type WorkOrderModalProps = {
  isOpen: boolean;
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  onClose: () => void;
};

export const WorkOrderModal = ({
  isOpen,
  originTeachers,
  originRooms,
  eventStart,
  eventEnd,
  onClose,
}: WorkOrderModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal width='s' title={t('substitutions.workOrder')} isOpen={isOpen} onRequestClose={onClose}>
      <WorkOrderForm
        originTeachers={originTeachers}
        originRooms={originRooms}
        eventStart={eventStart}
        eventEnd={eventEnd}
        onClose={onClose}
      />
    </Modal>
  );
};
