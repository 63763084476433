import { Page } from '../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { AdminEvents } from '../../../components/AdminEvents/AdminEvents';
import { Suspense } from 'react';
import { LazyLoader } from '@bp/ui-components';

export const EventsSubpage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('routes.events.title')}>
      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' fullWidth size='xxl' />}>
        <AdminEvents />
      </Suspense>
    </Page>
  );
};
