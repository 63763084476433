import {
  Button,
  Checkbox,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  LazyLoader,
  Row,
  RowSelectionState,
  Table,
  TableColumns,
  Tooltip,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import dayjs from 'dayjs';
import { useSubstitutionStore } from '../SubstitutionProvider';
import {
  SubstitutionDataGroupEntry,
  SubstitutionDataMissingTeacherEntry,
  SubstitutionDataEntry,
  SubstitutionDataSubstitutionTeacherEntry,
  SubstitutionDataTeacherEntry,
} from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { EventState, SubstitutionReason } from '@bp/planung-graphql-types';
import styles from './SubstitutionEventsTable.module.scss';

type SubstitutionEventsTableProps = {
  showAll: boolean;
  filter: string;
  data: SubstitutionEventsTableType[];
  onSubstitute: (uuid: string) => void;
  onCancelEvent: (uuid: string) => void;
  onMergeEvent: (eventUuid: string, groupUuid: string) => void;
  onWorkOrder: (uuid: string) => void;
  onDifferentTime: (uuid: string) => void;
  onRowSelect: (uuid: string) => void;
  onPageChange: () => void;
  onOutputChange: (uuid: string, output: boolean) => Promise<void>;
};

export type EventConflict = {
  state: EventState;
  affectedEntityUuid: string;
  conflictedEventUuid: string;
};

export type SubstitutionEventsTableType = {
  output: boolean;
  uuid: string;
  start: Date;
  end: Date;
  involvedClasses: SubstitutionDataEntry[];
  classes: SubstitutionDataEntry[];
  groups: SubstitutionDataGroupEntry[];
  subject: SubstitutionDataEntry;
  rooms: SubstitutionDataEntry[];
  teachers: SubstitutionDataTeacherEntry[];
  missingTeachers: SubstitutionDataMissingTeacherEntry[];
  substitutionTeachers: SubstitutionDataSubstitutionTeacherEntry[];
  action: string;
  comment: string;
  conflicts: EventConflict[];
  isSchoolEvent: boolean;
  substitutionReason: SubstitutionReason | null;
};

export const SubstitutionEventsTable = ({
  showAll,
  filter,
  data,
  onSubstitute,
  onCancelEvent,
  onMergeEvent,
  onWorkOrder,
  onDifferentTime,
  onRowSelect,
  onPageChange,
  onOutputChange,
}: SubstitutionEventsTableProps) => {
  const { t } = useTranslation();
  const { selectedDay } = useSubstitutionStore();

  const [selected, setSelected] = useState<RowSelectionState>({});
  const { sorting, saveSorting } = useColumnsSort('substitution-events');
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('substitution-events', {});

  const [checkboxLoading, setCheckboxLoading] = useState<string[]>([]);

  const tooltipFix = { maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', width: 'fit-content' };

  function getTooltipForSubstitutionReason(substitutionReason: SubstitutionReason) {
    if (substitutionReason === SubstitutionReason.Substituted) {
      return t('substitutions.reasons.substituted');
    }
    if (substitutionReason === SubstitutionReason.Aggregated) {
      return t('substitutions.reasons.aggregated');
    }
    if (substitutionReason === SubstitutionReason.Cancelled) {
      return t('substitutions.reasons.cancelled');
    }
    if (substitutionReason === SubstitutionReason.Moved) {
      return t('substitutions.reasons.moved');
    }
    if (substitutionReason === SubstitutionReason.SelfWork) {
      return t('substitutions.reasons.selfWork');
    }
    return '';
  }

  function getState(row: SubstitutionEventsTableType): ReactNode {
    let color = '#ACD6FB';
    let tooltip = t('substitutions.normalLesson');

    if (row.conflicts.some((c) => c.state === EventState.PersonNotAvailable)) {
      color = '#CB423A';
      tooltip = t('substitutions.personNotAvailable');
    } else if (row.conflicts.some((c) => c.state === EventState.PersonMissing)) {
      color = '#FCD05F';
      tooltip = t('substitutions.personMissing');
    } else if (row.conflicts.some((c) => c.state === EventState.PersonConflict)) {
      color = 'var(--color-grey-lighter)';
      tooltip = t('substitutions.personConflict');
    } else if (row.isSchoolEvent) {
      color = '#51A8F5';
      tooltip = t('schoolEvents.title', { count: 1 });
    } else if (row.substitutionReason) {
      color = '#4CAF50';
      tooltip = getTooltipForSubstitutionReason(row.substitutionReason);
    }

    return (
      <Tooltip triggerStyle={tooltipFix} content={tooltip}>
        <div className={styles['color-dot']} style={{ backgroundColor: color }}></div>
      </Tooltip>
    );
  }

  const columns: TableColumns<SubstitutionEventsTableType>[] = [
    {
      id: 'output',
      header: t('substitutions.print'),
      size: 40,
      accessorKey: 'output',
      cell: ({ row }) => {
        if (checkboxLoading.includes(row.original.uuid)) {
          return (
            <LazyLoader
              noPadding={true}
              embedded={true}
              iconPosition={'left'}
              transparent={true}
              size={'s'}
              forceHeight={`${50 - 8 - 8}px`}
            />
          );
        }
        return (
          <Checkbox
            name={`print-${row.original.uuid}`}
            onChange={async (value) => {
              if (checkboxLoading.includes(row.original.uuid)) return;
              setCheckboxLoading([...checkboxLoading, row.original.uuid]);
              await onOutputChange(row.original.uuid, value.target.checked);
              setCheckboxLoading(checkboxLoading.filter((uuid) => uuid !== row.original.uuid));
            }}
            checked={row.original.output}
          />
        );
      },
    },
    {
      id: 'state',
      header: t('substitutions.state'),
      cell: ({ row }) => {
        return getState(row.original);
      },
      size: 45,
    },
    {
      id: 'timeframe',
      header: t('common.time'),
      isMultiline: true,
      cell: ({ row }) => {
        return (
          <>
            {dayjs(row.original.start).format('HH:mm')}-&#8203;{dayjs(row.original.end).format('HH:mm')}
          </>
        );
      },
    },
    {
      header: `${t('classes.title', { count: 2 })}/${t('groups.title', { count: 2 })}`,
      accessorKey: 'classesAndGroups',
      id: 'classesAndGroups',
      cell: ({ row }) => {
        const classesLong = row.original.classes.map((c) => c.name).join(', ');
        const groupsLong = row.original.groups.map((g) => g.name).join(', ');
        const classesShort = row.original.classes.map((c) => c.shortName).join(', ');
        const groupsShort = row.original.groups.map((g) => g.shortName).join(', ');

        const long =
          classesLong && groupsLong
            ? classesLong + ', ' + groupsLong
            : classesLong && !groupsLong
              ? classesLong
              : !classesLong && groupsLong
                ? groupsLong
                : '---';

        const short =
          classesShort && groupsShort
            ? classesShort + ', ' + groupsShort
            : classesShort && !groupsShort
              ? classesShort
              : !classesShort && groupsShort
                ? groupsShort
                : '---';

        return (
          <Tooltip triggerStyle={tooltipFix} content={long}>
            {short}
          </Tooltip>
        );
      },
      size: 200,
    },
    {
      id: 'subjects',
      header: t('subject.title', { count: 1 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.subject.name}>
            {row.original.subject.shortName}
          </Tooltip>
        );
      },
    },
    {
      id: 'rooms',
      header: t('rooms.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.rooms.map((r) => r.name).join(', ')}>
            {row.original.rooms.map((r) => r.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'missingTeachers',
      header: t('substitutions.missing'),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.missingTeachers.map((t) => t.name).join(', ')}>
            {row.original.missingTeachers.map((t) => t.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'substitutionTeachers',
      header: t('substitutions.title', { count: 1 }),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.substitutionTeachers.map((t) => t.name).join(', ')}>
            {row.original.substitutionTeachers.map((t) => t.shortName).join(', ')}
          </Tooltip>
        );
      },
    },
    {
      id: 'action',
      header: t('substitutions.action'),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.action}>
            {row.original.action}
          </Tooltip>
        );
      },
    },
    {
      id: 'comment',
      header: t('common.comment'),
      cell: ({ row }) => {
        return (
          <Tooltip triggerStyle={tooltipFix} content={row.original.comment}>
            {row.original.comment}
          </Tooltip>
        );
      },
    },
  ];

  const menu = (row: Row<SubstitutionEventsTableType>): DropdownMenuItem[] => {
    return [
      {
        label: t('substitutions.title', { count: 1 }),
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          onSubstitute(row.original.uuid);
        },
      },
      {
        label: t('substitutions.cancelEvent'),
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          onCancelEvent(row.original.uuid);
        },
      },
      {
        label: t('substitutions.mergeEvent'),
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          onMergeEvent(row.original.uuid, row.original.groups[0].uuid);
        },
        disabled: row.original.groups.length === 0,
      },
      {
        label: t('substitutions.workOrder'),
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          onWorkOrder(row.original.uuid);
        },
      },
      {
        label: t('substitutions.differentTime'),
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          onDifferentTime(row.original.uuid);
        },
        disabled: true,
      },
      {
        type: 'ruler',
      },
      {
        label: t('substitutions.reset'),
        color: 'error',
        onClick: (e) => {
          const isSelected = selected[row.id];
          if (isSelected) {
            e.stopPropagation();
          }
          // TODO: implement
        },
        disabled: true,
      },
    ];
  };

  useEffect(() => {
    setSelected({});
    onRowSelect('');
  }, [selectedDay, showAll, onRowSelect]);

  return (
    <Table<SubstitutionEventsTableType>
      columns={columns}
      data={data}
      showBorderRadius
      showShadow
      rowSelection={selected}
      onRowClick={(event, row) => {
        const isSelected = selected[row.id];
        setSelected({ [row.id]: !isSelected });
        onRowSelect(isSelected ? '' : row.original.uuid);
      }}
      showRowHover
      selectOnClick
      showSelected
      showSort
      sorting={sorting}
      onSortingChange={saveSorting}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      globalFilter={filter}
      pageSize={15}
      showPagination
      onPaginationChange={onPageChange}
      lastColWidth='36px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon />} />}>
            <DropdownMenu data={menu(row)} />
          </Dropdown>
        );
      }}
      className={styles['substitution-events-table']}
    />
  );
};
