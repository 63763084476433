import dayjs, { Dayjs } from 'dayjs';
import styles from './SubstitutionFormRow.module.scss';
import { Checkbox, CheckIcon, Input } from '@bp/ui-components';
import classNames from 'classnames';
import { getMinutesDifference } from '../../../utils/dateCalculations';
import { useFormikContext } from 'formik';
import { SubstitutionFormType } from './SubstitutionForm';

export type SubstitutionFormRowStatus =
  | 'normal'
  | 'attendance'
  | 'cancelation-class-trip'
  | 'substitution'
  | 'on-class-trip'
  | 'signed-out';

export type SubstitutionFormRowEventType = {
  eventUuid: string;
  eventStatus: SubstitutionFormRowStatus;
  start: Date;
  end: Date;
  name: string;
  subjectShortName: string;
  subjectName: string;
  classOrGroupNames: string;
};

export type SubstitutionFormRowType = {
  teacherUuid: string;
  teacherName: string;
  selected: boolean;
  givenSubstituteHours: number | null;
  target: number;
  current: number;
  sameClass: boolean;
  sameSubject: boolean;
  events: SubstitutionFormRowEventType[];
  rowStatus: SubstitutionFormRowStatus;
  fullDay: boolean;
  disabled: boolean;
};

type SubstitutionFormRowProps = {
  hours: Dayjs[];
  hourWidth: number;
  frames: { left: number; width: number }[];
  row: SubstitutionFormRowType;
  index: number;
};

export const SubstitutionFormRow = ({ hours, hourWidth, frames, row, index }: SubstitutionFormRowProps) => {
  const {
    teacherUuid,
    teacherName,
    selected,
    givenSubstituteHours,
    target,
    current,
    sameClass,
    sameSubject,
    events,
    rowStatus,
    fullDay,
    disabled,
  } = row;

  const { setFieldValue, setFieldTouched } = useFormikContext<SubstitutionFormType>();

  return (
    <div
      className={classNames(styles['substitution-form-row'], {
        [styles.selected]: selected,
        [styles['row-status-' + rowStatus]]: fullDay,
      })}
    >
      <div className={styles.data}>
        <div className={styles.tiny}>
          <Checkbox
            checked={selected}
            name={`select-${teacherUuid}`}
            onChange={async (value) => {
              await setFieldValue(`substitutionRows[${index}].selected`, value.target.checked);
              if (!value.target.checked) {
                await setFieldValue(`substitutionRows[${index}].givenSubstituteHours`, null);
              } else {
                await setFieldValue(`substitutionRows[${index}].givenSubstituteHours`, 1);
              }
            }}
            disabled={fullDay || disabled}
          />
        </div>
        <div className={styles.big} title={teacherName}>
          {teacherName}
        </div>
        <div className={styles.small}>
          {
            <Input
              type='number'
              dense
              name={`value-${teacherUuid}`}
              disabled={!selected}
              onChange={async (event) => {
                const value = Number(event.target.value);
                await setFieldTouched(`substitutionRows[${index}].givenSubstituteHours`, true);
                await setFieldValue(`substitutionRows[${index}].givenSubstituteHours`, value);
              }}
              value={givenSubstituteHours}
              className={styles['value-input']}
            />
          }
        </div>
        <div className={styles.small}>{target}</div>
        <div className={styles.small}>{current}</div>
        <div className={styles.medium}>{sameClass && <CheckIcon />}</div>
        <div className={styles.medium}>{sameSubject && <CheckIcon />}</div>
      </div>

      <div className={styles.hours} style={{ gridTemplateColumns: `repeat(${hours.length}, 1fr)` }}>
        {hours.map((hour) => {
          return <div key={hour.toString()} className={styles.hour}></div>;
        })}

        {hours.map((hour, hourIndex) => {
          return events
            .filter((event) => {
              return dayjs(event.start).hour() === hour.hour();
            })
            .map((event) => {
              const {
                eventUuid: uuid,
                eventStatus: status,
                start,
                end,
                subjectName,
                subjectShortName,
                classOrGroupNames,
                name,
              } = event;

              const difference = getMinutesDifference(hour, start);
              const duration = getMinutesDifference(start, end);

              const offset = Math.ceil((hourWidth / 60) * difference + hourIndex * hourWidth);
              const width = Math.ceil((hourWidth / 60) * duration);

              const title = `${subjectName}\n${classOrGroupNames}\n${teacherName}\n${name}`;

              return (
                <div
                  key={`${uuid}-${teacherUuid}-${hour.toDate()}-${start.toDateString()}`}
                  className={classNames(styles.event, styles['event-status-' + status])}
                  style={{ left: `${offset}px`, width: `${width - 1}px` }}
                  title={title}
                >
                  <div className={styles.name} title={title}>
                    {subjectShortName}
                  </div>
                </div>
              );
            });
        })}

        {frames.map(({ left, width }, index) => {
          return (
            <div
              key={left + width + index}
              className={styles.frame}
              style={{ left: `${left}px`, width: `${width}px` }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
