import { AbsenceReason } from '@bp/planung-graphql-types';
import { SelectOptionType } from '@bp/ui-components';
import { t } from 'i18next';

function getAbsenceReasonTranslation(reason: AbsenceReason): string {
  switch (reason) {
    case AbsenceReason.Business:
      return t('absences.reasons.business');
    case AbsenceReason.ChildCare:
      return t('absences.reasons.childCare');
    case AbsenceReason.Emergency:
      return t('absences.reasons.emergency');
    case AbsenceReason.Illness:
      return t('absences.reasons.illness');
    case AbsenceReason.OnLeave:
      return t('absences.reasons.onLeave');
    case AbsenceReason.Other:
      return t('absences.reasons.other');
    case AbsenceReason.Privat:
      return t('absences.reasons.private');
    case AbsenceReason.Training:
      return t('absences.reasons.training');
    default:
      return 'reason not found';
  }
}

function getAbsenceReasonSelectOptions(): SelectOptionType[] {
  const options: SelectOptionType[] = [
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Illness),
      value: AbsenceReason.Illness,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Training),
      value: AbsenceReason.Training,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Business),
      value: AbsenceReason.Business,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.OnLeave),
      value: AbsenceReason.OnLeave,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.ChildCare),
      value: AbsenceReason.ChildCare,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Privat),
      value: AbsenceReason.Privat,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Other),
      value: AbsenceReason.Other,
    },
    {
      label: getAbsenceReasonTranslation(AbsenceReason.Emergency),
      value: AbsenceReason.Emergency,
    },
  ];
  return options;
}

export { AbsenceReason, getAbsenceReasonTranslation, getAbsenceReasonSelectOptions };
