import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SubstitutionForm } from '../forms/SubstitutionForm';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import { SubstitutionEventsTableType } from '../tables/SubstitutionEventsTable';

type SubstitutionModalProps = {
  isOpen: boolean;
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  eventUuid: string;
  timetableStart: Date;
  timetableEnd: Date;
  events: SubstitutionEventsTableType[];
  onClose: () => void;
};

export const SubstitutionModal = ({
  isOpen,
  originTeachers,
  originRooms,
  eventStart,
  eventUuid,
  eventEnd,
  timetableStart,
  timetableEnd,
  events,
  onClose,
}: SubstitutionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal width='l' title={t('substitutions.substituteEvent')} isOpen={isOpen} onRequestClose={onClose}>
      <SubstitutionForm
        eventUuid={eventUuid}
        originTeachers={originTeachers}
        originRooms={originRooms}
        eventStart={eventStart}
        eventEnd={eventEnd}
        timetableStart={timetableStart}
        timetableEnd={timetableEnd}
        events={events}
        onClose={onClose}
      />
    </Modal>
  );
};
