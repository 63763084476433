import { Input, ModalBottomButtons, TimetableView, TimetableViewEntryType } from '@bp/ui-components';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { SubstitutionDataEntry } from '../../../pages/Substitutions/Plan/PlanSubstitutions/PlanSubstitutions';
import styles from './MergeEventForm.module.scss';
import { SubstitutionRoomsSelect } from '../components/SubstitutionRoomsSelect';
import { isSame } from '../../../utils/dateCalculations';
import { useCallback, useMemo, useState } from 'react';

type MergeEventFormProps = {
  originTeachers: SubstitutionDataEntry[];
  originRooms: SubstitutionDataEntry[];
  eventStart: Date;
  eventEnd: Date;
  timetableStart: Date;
  timetableEnd: Date;
  timetableEntries: TimetableViewEntryType[];
  originalEntryUuid: string;
  onClose: () => void;
};

type MergeEventFormType = {
  rooms: string[];
  comment: string;
  selectedEntry: TimetableViewEntryType | null;
};

export const MergeEventForm = ({
  originTeachers,
  originRooms,
  eventStart,
  eventEnd,
  timetableStart,
  timetableEnd,
  timetableEntries,
  originalEntryUuid,
  onClose,
}: MergeEventFormProps) => {
  const { t } = useTranslation();

  const [selectedEntry, setSelectedEntry] = useState<TimetableViewEntryType | null>(null);

  const initialValues: MergeEventFormType = {
    rooms: [],
    comment: '',
    selectedEntry: null,
  };

  const handleEntrySelect = useCallback(
    (entry: TimetableViewEntryType) => {
      if (selectedEntry && selectedEntry.uuid === entry.uuid) {
        setSelectedEntry(null);
      } else {
        setSelectedEntry(entry);
      }
    },
    [selectedEntry],
  );

  const originalEntry = timetableEntries.find((entry) => entry.group?.uuid === originalEntryUuid);

  const entries: TimetableViewEntryType[] = useMemo(() => {
    return timetableEntries.map((entry) => {
      const isOriginal = entry.uuid === originalEntry?.uuid;
      const isSelectable = entry.group && isSame(entry.start, originalEntry?.start, 'hour');
      const isSelected = entry.uuid === selectedEntry?.uuid;

      let newEntry: TimetableViewEntryType = {
        ...entry,
        bgColor: 'var(--color-white)',
        color: 'var(--color-grey-lighter)',
        border: '1px solid var(--color-grey-lightest)',
      };

      if (isOriginal) {
        newEntry = {
          ...entry,
          bgColor: 'var(--color-grey-lightest)',
          border: '2px solid var(--color-primary)',
        };
      } else if (isSelectable) {
        newEntry = {
          ...entry,
          bgColor: 'var(--color-grey-lightest)',
          border: '2px solid var(--color-grey-lighter)',
          onClick: () => handleEntrySelect(entry),
        };
      }

      if (isSelected) {
        newEntry = {
          ...entry,
          bgColor: '#a7dd8e',
          color: 'var(--color-grey)',
          border: '2px solid var(--color-success)',
          onClick: () => handleEntrySelect(entry),
        };
      }

      return newEntry;
    });
  }, [selectedEntry, handleEntrySelect, originalEntry?.start, originalEntry?.uuid, timetableEntries]);

  async function handleSubmit(values: MergeEventFormType, formHelpers: FormikHelpers<MergeEventFormType>) {
    // TODO: implement
  }

  return (
    <Formik<MergeEventFormType> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form className={styles['merge-event-form']}>
            <div className={styles.info}>
              <div>
                {t('substitutions.initialTeacher')}:
                <span className={styles.bold}>
                  {originTeachers.length > 0 ? originTeachers.map((t) => t.name).join(', ') : t('common.none')}
                </span>
              </div>
              <div>
                {t('substitutions.initialRooms')}:
                <span className={styles.bold}>
                  {originRooms.length > 0 ? originRooms.map((r) => r.name).join(', ') : t('common.none')}
                </span>
              </div>
            </div>

            <div className={styles.hint}>
              <div className={styles['hint-text']}>{t('substitutions.mergeEventHint')}</div>
              <div className={styles.legend}>
                <div className={styles.original}>
                  <div className={styles.example}></div>
                  <div className={styles['legend-text']}>{t('substitutions.mergeCurrentHint')}</div>
                </div>
                <div className={styles.selectable}>
                  <div className={styles.example}></div>
                  <div className={styles['legend-text']}>{t('substitutions.mergeSelectableHint')}</div>
                </div>
                <div className={styles.selected}>
                  <div className={styles.example}></div>
                  <div className={styles['legend-text']}>{t('substitutions.mergeSelectedHint')}</div>
                </div>
              </div>
            </div>

            <div className={styles.timetable}>
              <TimetableView gridHeight='s' showTimegrid start={timetableStart} end={timetableEnd} entries={entries} />
            </div>

            <SubstitutionRoomsSelect
              eventRoomUuids={originRooms.map((r) => r.uuid)}
              eventStart={eventStart}
              eventEnd={eventEnd}
              onChange={async (values) => await setFieldValue('rooms', values)}
              error={errors.rooms ? (errors.rooms as string) : null}
            />

            <Input
              name='comment'
              label={t('common.comment')}
              onChange={async (e) => setFieldValue('comment', e.target.value)}
              value={values.comment}
            />

            <ModalBottomButtons
              className={styles.bottom}
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              submitButton={{
                disabled: isSubmitting || !dirty,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
