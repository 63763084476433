import { Outlet, RouteObject } from 'react-router-dom';
import { SubstitutionsPage } from './SubstitutionsPage';
import { substitutionEvaluateRoutes } from './Evaluate/routes';
import { substitutionSetupRoutes } from './Setup/routes';
import { substitutionPlanRoutes } from './Plan/routes';
import { t } from 'i18next';
import { SubstitutionProvider } from '../../components/Substitutions/SubstitutionProvider';

export const substitutionsRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SubstitutionsPage />,
    },
    {
      path: t('routes.setup.slug') + '/*',
      element: <Outlet />,
      children: [...substitutionSetupRoutes(`${baseSlug}/${t('routes.setup.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.setup.slug')}`,
          title: t('routes.setup.title'),
        }),
      },
    },
    {
      path: t('routes.plan.slug') + '/*',
      element: (
        <SubstitutionProvider>
          <Outlet />
        </SubstitutionProvider>
      ),
      children: [...substitutionPlanRoutes(`${baseSlug}/${t('routes.plan.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.plan.slug')}`,
          title: t('routes.plan.title'),
        }),
      },
    },
    {
      path: t('routes.evaluate.slug') + '/*',
      element: <Outlet />,
      children: [...substitutionEvaluateRoutes(`${baseSlug}/${t('routes.evaluate.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.evaluate.slug')}`,
          title: t('routes.evaluate.title'),
        }),
      },
    },
  ];
};
