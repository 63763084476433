import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { StoreHandler } from '../../stores/StoreHandler';
import { SubstitutionStore } from '../../stores/SubstitutionStore';
import { LazyLoader } from '@bp/ui-components';

const substitutionStoreHandler = new StoreHandler<SubstitutionStore>();
const SubstitutionStoreContext = createContext<SubstitutionStore>({} as SubstitutionStore);

export const SubstitutionProvider: FC<PropsWithChildren> = ({ children }) => {
  const substitutionStore = useMemo(() => {
    return substitutionStoreHandler.register('substitution-store', () => {
      return new SubstitutionStore();
    });
  }, []);

  return (
    <>
      {substitutionStore ? (
        <SubstitutionStoreContext.Provider value={substitutionStore}>{children}</SubstitutionStoreContext.Provider>
      ) : (
        <LazyLoader transparent forceHeight='60vh' />
      )}
    </>
  );
};

export const useSubstitutionStore = () => useContext(SubstitutionStoreContext);
